import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

export const getShowBullets = (category: CreditCardProductCategory) => {
    return (
        category === CreditCardProductCategory.PoorCredit ||
        category === CreditCardProductCategory.Rewards ||
        category === CreditCardProductCategory.Travel ||
        category === CreditCardProductCategory.Cashback
    )
}
