import { Formik, FormikHelpers } from 'formik'
import { useEffect } from 'react'

import useAppQueryState from '@hooks/useAppQueryState'
import useLogEvents from '@hooks/useLogEvents'

import { useMinimumGuaranteedCreditLimitMutation } from '@queries/useMinimumGuaranteedCreditLimitMutation'

import GuaranteedCreditLimitIntroForm from './components/GuaranteedCreditLimitIntroForm'
import {
    GuaranteedCreditLimitIntroFormSchema,
    GuaranteedCreditLimitIntroFormValues,
} from './components/GuaranteedCreditLimitIntroForm/GuaranteedCreditLimitIntroForm.validation'

interface GuaranteedCreditLimitIntroFormWrapperProps {
    onFormClose: () => void
}

const GuaranteedCreditLimitIntroFormWrapper = ({
    onFormClose,
}: GuaranteedCreditLimitIntroFormWrapperProps) => {
    const { mutateAsync } = useMinimumGuaranteedCreditLimitMutation()
    const logEvents = useLogEvents()
    const { appQueryState } = useAppQueryState()

    async function onFindCardsPress(
        values: GuaranteedCreditLimitIntroFormValues,
        formikHelpers: FormikHelpers<GuaranteedCreditLimitIntroFormValues>
    ) {
        const minCreditLimit = values.showCustomField
            ? values.minCreditLimitCustom
            : values.minCreditLimitOption

        if (minCreditLimit == null) {
            return
        }

        formikHelpers.setStatus(undefined)

        try {
            await mutateAsync({
                minimumGuaranteedCreditLimit: Number(minCreditLimit),
                customerId: appQueryState.customerId!,
            })

            logEvents.logMinCreditLimitSubmit({
                minCreditLimit,
                source: 'GuaranteedCreditLimitIntro',
            })

            onFormClose()
        } catch (err) {
            formikHelpers.setStatus(
                'Failed to set your ideal credit limit. Please try again.'
            )
        }
    }

    async function onCardsTableContinuePress() {
        logEvents.logMinCreditLimitSkip({
            source: 'GuaranteedCreditLimitIntro',
        })

        onFormClose()
    }

    useEffect(() => {
        logEvents.logCreditLimitIntroView()
    }, [])

    return (
        <Formik
            onSubmit={onFindCardsPress}
            initialValues={
                {
                    minCreditLimitCustom: null,
                    minCreditLimitOption: null,
                } as GuaranteedCreditLimitIntroFormValues
            }
            validateOnChange={true}
            validationSchema={GuaranteedCreditLimitIntroFormSchema}
        >
            <GuaranteedCreditLimitIntroForm
                onFormClose={onCardsTableContinuePress}
            />
        </Formik>
    )
}

export default GuaranteedCreditLimitIntroFormWrapper
