const metaDataResource = {
    Landing: {
        title: 'Looking for a Credit Card? Compare UK 0% Credit Card Deals',
        description:
            '0% Balance Transfers? 0% Purchases? Cashback? Have Bad Credit? Compare the Leading Credit Card Deals and Find the Right Credit Card for You. Apply Now!',
        canonical: 'https://www.totallymoney.com/credit-cards/',
    },
    AllCards: {
        title: 'Compare the UK’s Best Credit Cards with TotallyMoney',
        description:
            'Searching for the Best Credit Card? TotallyMoney can help you Find the Right Card. Quick Decision, Apply Today!',
        canonical: 'https://www.totallymoney.com/credit-cards/',
    },
    BestCards: {
        title: 'Compare the UK’s Best Credit Cards with TotallyMoney',
        description:
            'Searching for the Best Credit Card? TotallyMoney can help you Find the Right Card. Quick Decision, Apply Today!',
        canonical: 'https://www.totallymoney.com/credit-cards/',
    },
    BalanceTransfer: {
        title: 'Compare the Top 0% Balance Transfer Credit Card Offers with TotallyMoney',
        description:
            'Searching for a balance transfer credit card? TotallyMoney compares the top 0% balance transfer cards. Apply today!',
        canonical:
            'https://www.totallymoney.com/credit-cards/balance-transfer/',
    },
    Purchase: {
        title: 'Compare the Top 0% Purchase Credit Card Deals with TotallyMoney',
        description:
            'Searching for a Purchase credit card? TotallyMoney compares the top interest free purchase credit cards. Apply today!',
        canonical: 'https://www.totallymoney.com/credit-cards/purchase/',
    },
    Cashback: {
        title: 'Compare the Top Cash Back Credit Card Offers Online with TotallyMoney',
        description:
            'Searching for a cash back credit card? TotallyMoney compares the top cash back reward deals. Apply today!',
        canonical: 'https://www.totallymoney.com/credit-cards/cashback/',
    },
    Rewards: {
        title: 'Compare the Top Reward Credit Card Schemes Online with TotallyMoney',
        description:
            'Searching for a reward credit card? TotallyMoney compares the top reward credit cards in the UK. Apply today!',
        canonical: 'https://www.totallymoney.com/credit-cards/rewards/',
    },
    Combined: {
        title: 'Compare the Best 0% Balance Transfer and Spending Cards',
        description:
            'Searching for a 0% credit card? TotallyMoney compares the top 0% percent cards. Apply today!',
        canonical: 'https://www.totallymoney.com/credit-cards/0-percent/',
    },
    PoorCredit: {
        title: 'Credit Cards for People with Bad Credit - Compare deals with TotallyMoney',
        description:
            'Searching for a Bad Credit Credit Card? - We’ve Helped 1000s Get Credit. Up To £1600 Credit Limit and High Approval - Apply Now!',
        canonical:
            'https://www.totallymoney.com/credit-cards/credit-cards-bad-credit/',
    },
    Travel: {
        title: 'Compare the Best Travel Credit Card Offers with TotallyMoney',
        description:
            'Searching for credit card to travel? TotallyMoney compares the top cards for overseas travel. Apply today!',
        canonical: 'https://www.totallymoney.com/credit-cards/travel/',
    },
} as const

export function getCategoryMetadata(category: keyof typeof metaDataResource) {
    return metaDataResource[category ?? metaDataResource.BestCards]
}
