import { useEffect } from 'react'

import useFeatureFlagContext from '@hooks/useFeatureFlagContext'
import useLogEvents from '@hooks/useLogEvents'

import GuaranteedCreditLimitIntroRoute from '@routes/GuaranteedCreditLimitIntroRoute'

const ScoredJourneyRoute = () => {
    const { isLoading } = useFeatureFlagContext()
    const logEvents = useLogEvents()

    useEffect(() => {
        if (!isLoading) {
            logEvents.logGuaranteedCreditLimitLanding()
        }
    }, [isLoading])

    return <GuaranteedCreditLimitIntroRoute />
}

export default ScoredJourneyRoute
