import { ScoredCreditCardProduct } from '@appTypes/EligibilityApi.interface'

export type HighlightState =
    | 'combined'
    | 'guaranteedApr'
    | 'guaranteedCreditLimit'
    | null

export function getCardProductDetailHighlightState(
    card: ScoredCreditCardProduct
): HighlightState {
    const hasGuaranteedAPR = card.guaranteedRate?.guaranteedAPR != null
    const hasGuaranteedCreditLimit =
        card.guaranteedRate?.guaranteedCreditLimit != null

    return hasGuaranteedAPR && hasGuaranteedCreditLimit
        ? 'combined'
        : hasGuaranteedAPR
        ? 'guaranteedApr'
        : hasGuaranteedCreditLimit
        ? 'guaranteedCreditLimit'
        : null
}
