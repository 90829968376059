import Box from '@totallymoney/ui/components/Box'
import LinkButton from '@totallymoney/ui/components/LinkButton'
import { useEffect } from 'react'

import { getAppConfig } from '@utils/getAppConfig'
import { AuthErrorType, getAuthErrorInfo } from '@utils/getAuthErrorInfo'
import { getIsExpiredScorePageError } from '@utils/getIsExpiredScorePageError'

import useLogger from '@hooks/useLogger'

import AnimatedLoadingPage from '@components/AnimatedLoadingPage'
import ErrorPage from '@components/ErrorPage'

import { LoggerKey } from '@config/logger'

import ScoredTableSkeleton from '../ScoredTableSkeleton'

interface ScoredTableErrorProps {
    error?: unknown
}

const ScoredTableError = ({ error }: ScoredTableErrorProps) => {
    const { logInfo } = useLogger()

    const isExpiredScorePageError = getIsExpiredScorePageError(error)
    const authError = getAuthErrorInfo(error)

    function onOffersRedirect() {
        logInfo(LoggerKey.ScoredTableErrorOffersRedirect, {})
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            logInfo(LoggerKey.ScoredTableErrorView, {
                expired: isExpiredScorePageError,
                auth: authError?.errorType,
            })
        }
    }, [])

    if (authError != null) {
        const authErrorTitle =
            authError.errorType === AuthErrorType.MagicLinkToken
                ? 'Sorry, the link has expired'
                : 'Sorry, we need you to log in again'

        return (
            <>
                <AnimatedLoadingPage
                    heading={authErrorTitle}
                    supportingText="Redirecting you to login"
                    fill={false}
                />
                <ScoredTableSkeleton />
            </>
        )
    }

    if (isExpiredScorePageError) {
        return (
            <ErrorPage
                title="Something went wrong!"
                subtitle="Please log in to check your eligibility for credit cards"
            >
                <Box pt="space30" maxWidth={['100%', '280px']}>
                    <LinkButton
                        variant="primary"
                        text="Log in"
                        href={`${getAppConfig().ACCOUNT_URL}offers`}
                        onClick={onOffersRedirect}
                    />
                </Box>
            </ErrorPage>
        )
    }

    return (
        <ErrorPage
            title="Something went wrong!"
            subtitle="Oops! The credit cards service is unavailable right now."
        >
            <Box pt="space30" maxWidth={['100%', '280px']}>
                <LinkButton variant="primary" text="Try again" href="" />
            </Box>
        </ErrorPage>
    )
}

export default ScoredTableError
