import { captureException } from '@sentry/nextjs'
import Box from '@totallymoney/ui/components/Box'
import Grid from '@totallymoney/ui/components/Grid'
import LinkButton from '@totallymoney/ui/components/LinkButton'
import Stack from '@totallymoney/ui/components/Stack'
import theme from '@totallymoney/ui/theme'
import React, { useMemo } from 'react'

import getCategoryByCategoryId from '@utils/getCategoryByCategoryId'
import { getCategoryPathForId } from '@utils/getCategoryPathForId'
import { getIsDormantScoreIdJourney } from '@utils/getIsDormantScoreIdJourney'
import { getSanitisedCardName } from '@utils/getSanitisedCardName'
import { sortCardsBySortKey } from '@utils/sortCardsBySortKey'

import {
    ApplicationURLTriggerLocation,
    CreditCardProductCategory,
    ScoredCreditCardProduct,
    ScoredCreditCardProductsCategory,
} from '@appTypes/EligibilityApi.interface'

import useAppQueryState from '@hooks/useAppQueryState'
import useLogEvents from '@hooks/useLogEvents'
import useSetState from '@hooks/useSetState'

import { getRedirectAccountUrl } from '@routes/UnscoredTableRoute/utils/getRedirectAccountUrl'

import CardTablePagination from '@components/CardTablePagination'
import ErrorBoundary from '@components/ErrorBoundary'
import NextLinkButton from '@components/NextLinkButton'
import RepresentativeExampleModal from '@components/RepresentativeExampleModal'

import UnscoredTableHeader from './components/UnscoredTableHeader'
import UnscoredTableItem from './components/UnscoredTableItem'
import UnscoredTableNoResults from './components/UnscoredTableNoResults'
import {
    CategoryComparatorKey,
    CategorySortKeyComparatorKeys,
    categorySortKeyComparatorKeys,
} from './utils/categorySortKeyComparatorKeys'
import { getCategorySortKeysToShow } from './utils/getCategorySortKeysToShow'
import { getValidCategorySortKey } from './utils/getValidCategorySortKey'

interface UnscoredTableProps {
    unscoredCategories: ScoredCreditCardProductsCategory[]
    activeCategoryId: CreditCardProductCategory
}

interface CreditCardsTableState {
    selectedCategory: CreditCardProductCategory
    selectedSortKey: keyof CategorySortKeyComparatorKeys[CreditCardProductCategory]
    numberOfCardsToShow: number
}

const NUMBER_OF_CARDS_TO_SHOW = 15

enum ModalType {
    RepresentativeExample = 'RepresentativeExample',
}

const UnscoredTable = ({
    unscoredCategories,
    activeCategoryId,
}: UnscoredTableProps) => {
    const {
        appQueryState,
        updateAppQueryState,
        goToRouteWithAppState,
        getRouteWithAppState,
        getQueryStringWithAppState,
    } = useAppQueryState()

    const logEvents = useLogEvents()

    const [creditCardsState, setCreditCardsState] =
        useSetState<CreditCardsTableState>({
            selectedCategory: appQueryState.categoryId!,
            selectedSortKey: getValidCategorySortKey({
                categoryId: appQueryState.categoryId!,
                queryOption: appQueryState.sortBy as CategoryComparatorKey,
            }),
            numberOfCardsToShow:
                appQueryState.numberOfCardsToShow ?? NUMBER_OF_CARDS_TO_SHOW,
        })
    const [showModal, setShowModal] = React.useState<ModalType | null>(null)

    const activeCategoryPath = getCategoryPathForId(
        creditCardsState.selectedCategory
    )

    const activeCategory = getCategoryByCategoryId(
        unscoredCategories!,
        creditCardsState.selectedCategory
    )

    const isAllCardsCategory = activeCategoryPath === 'all-cards'

    const activeCategoryCreditCards = React.useMemo(() => {
        if (activeCategory == null) {
            return null
        }

        const sortedCards = sortCardsBySortKey({
            cards: activeCategory!.creditCards,
            category: creditCardsState.selectedCategory,
            sortKey: getValidCategorySortKey({
                categoryId: creditCardsState.selectedCategory,
                queryOption: creditCardsState.selectedSortKey,
            }),
            categorySortKeyComparatorKeys,
        })
        return sortedCards
    }, [activeCategory, creditCardsState.selectedSortKey])

    const activeSortOptions = useMemo(() => {
        return getCategorySortKeysToShow(creditCardsState.selectedCategory)
    }, [creditCardsState.selectedCategory])

    const onCategoryChange = (category: CreditCardProductCategory) => {
        setCreditCardsState({
            selectedCategory: category,
            selectedSortKey: getValidCategorySortKey({
                categoryId: category,
                queryOption: creditCardsState.selectedSortKey,
            }),
            numberOfCardsToShow: NUMBER_OF_CARDS_TO_SHOW,
        })
    }

    const onSortChange = (
        sortKey: keyof CategorySortKeyComparatorKeys[CreditCardProductCategory]
    ) => {
        logEvents.logSortChangeEvent({
            sortBy: sortKey,
            category: creditCardsState.selectedCategory,
        })

        setCreditCardsState({ selectedSortKey: sortKey })

        updateAppQueryState({
            sortby: sortKey,
        })
    }

    const onShowMoreResults = () => {
        const numberOfCardsToShow =
            creditCardsState.numberOfCardsToShow + NUMBER_OF_CARDS_TO_SHOW

        setCreditCardsState({
            numberOfCardsToShow: numberOfCardsToShow,
        })

        updateAppQueryState({
            show: `${numberOfCardsToShow}`,
        })
    }

    const onCheckEligibilityButtonPress = ({
        card,
        cardPosition,
        formUrl,
    }: {
        card: ScoredCreditCardProduct
        cardPosition: number
        formUrl: string
    }) => {
        if (getIsDormantScoreIdJourney(appQueryState)) {
            goToRouteWithAppState(appQueryState.category!, {
                queryParameters: {
                    scoreId: appQueryState.dormantScoreId,
                    customerId: appQueryState.customerId,
                },
            })
            return
        }

        logEvents.logCheckEligibilityEvent({
            category: activeCategoryPath,
            rowNumber: cardPosition,
            productCode: card.productCode,
            trigger: ApplicationURLTriggerLocation.Row,
        })

        window.location.href = formUrl
    }

    const onSeeMoreDetailsPress = ({
        card,
        cardPosition,
    }: {
        card: ScoredCreditCardProduct
        cardPosition: number
    }) => {
        logEvents.logNavigateToCardPageEvent({
            productCode: card.productCode,
            rowNumber: cardPosition,
            trigger: ApplicationURLTriggerLocation.Row,
        })
    }

    React.useEffect(() => {
        setCreditCardsState({ selectedCategory: activeCategoryId })
    }, [activeCategoryId])

    return (
        <Box bg={theme.productNeutral60} py="space20">
            <Box mb="space50">
                <UnscoredTableHeader
                    categories={unscoredCategories}
                    activeCategory={activeCategoryId}
                    onCategoryChange={onCategoryChange}
                    sortValue={creditCardsState.selectedSortKey}
                    onSortChange={sortKey =>
                        onSortChange(
                            sortKey as keyof CategorySortKeyComparatorKeys[CreditCardProductCategory]
                        )
                    }
                    sortOptions={activeSortOptions}
                />
            </Box>
            <Box mb="space50">
                <Grid>
                    {activeCategoryCreditCards == null ? (
                        <UnscoredTableNoResults />
                    ) : null}
                    <Box
                        as="ul"
                        gridColumn={'1/13'}
                        display="grid"
                        gridGap="space30"
                        mb="space30"
                    >
                        {activeCategoryCreditCards
                            ?.slice(0, creditCardsState.numberOfCardsToShow)
                            .map((card, index) => {
                                const formUrl = getRedirectAccountUrl({
                                    queryObject: {
                                        category: isAllCardsCategory
                                            ? ''
                                            : activeCategoryPath,
                                        card: getSanitisedCardName(card.name),
                                        ccid: card.productCode,
                                    },
                                    queryString: getQueryStringWithAppState(),
                                })

                                const seeMoreDetailsUrl = getRouteWithAppState(
                                    isAllCardsCategory
                                        ? [
                                              'card',
                                              getSanitisedCardName(card.name),
                                          ]
                                        : [
                                              activeCategoryPath,
                                              'card',
                                              getSanitisedCardName(card.name),
                                          ]
                                )

                                return (
                                    <ErrorBoundary
                                        key={card.productCode}
                                        fallback={null}
                                        onError={({ error }) =>
                                            captureException(error)
                                        }
                                    >
                                        <Box as="li">
                                            <UnscoredTableItem
                                                item={card}
                                                category={
                                                    creditCardsState.selectedCategory
                                                }
                                                onShowRepresentativeExampleModal={() =>
                                                    setShowModal(
                                                        ModalType.RepresentativeExample
                                                    )
                                                }
                                                renderButtons={() => (
                                                    <Stack
                                                        space="space10"
                                                        display="flex"
                                                        alignItems={[
                                                            'center',
                                                            'unset',
                                                            'unset',
                                                        ]}
                                                    >
                                                        <LinkButton
                                                            href={formUrl}
                                                            variant="primary"
                                                            text="Check my eligibility"
                                                            onClick={event => {
                                                                event.preventDefault()
                                                                onCheckEligibilityButtonPress(
                                                                    {
                                                                        card,
                                                                        cardPosition:
                                                                            index,
                                                                        formUrl,
                                                                    }
                                                                )
                                                            }}
                                                            data-gtm-event="eligibility-table-cardSpecific"
                                                        />
                                                        <NextLinkButton
                                                            to={
                                                                seeMoreDetailsUrl
                                                            }
                                                            shallow={true}
                                                            text="See more details"
                                                            variant="tertiaryTransparent"
                                                            onClick={() =>
                                                                onSeeMoreDetailsPress(
                                                                    {
                                                                        card,
                                                                        cardPosition:
                                                                            index,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    </Stack>
                                                )}
                                            />
                                        </Box>
                                    </ErrorBoundary>
                                )
                            })}
                    </Box>
                </Grid>
            </Box>
            <Grid>
                <Box gridColumn={'1/13'} mb="space30">
                    <CardTablePagination
                        numberOfCardsToShow={
                            creditCardsState.numberOfCardsToShow
                        }
                        showMoreResultsButton={
                            creditCardsState.numberOfCardsToShow <
                            (activeCategory?.totalCreditCards ?? 0)
                        }
                        totalCreditCards={activeCategory?.totalCreditCards ?? 0}
                        onShowMoreResults={onShowMoreResults}
                    />
                </Box>
            </Grid>
            <RepresentativeExampleModal
                showModal={showModal == ModalType.RepresentativeExample}
                onModalClose={() => setShowModal(null)}
            />
        </Box>
    )
}

export default UnscoredTable
