import Badge from '@totallymoney/ui/components/Badge'
import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'
// @ts-ignore
import Tick from '@totallymoney/ui/icons/Tick'
import theme from '@totallymoney/ui/theme'

import { getCardApr } from '@utils/getCardApr'
import { getCardProductDetailHighlightState } from '@utils/getCardProductDetailHighlightState'
import getGuaranteedOfferType from '@utils/getGuaranteedOfferType'
import { getIsProductPortraitImage } from '@utils/getIsProductPortraitImage'

import {
    CreditCardProductCategory,
    GuaranteedOfferType,
    OfferType,
    ScoredCreditCardProduct,
} from '@appTypes/EligibilityApi.interface'
import { GetGuaranteedCreditLimitResponse } from '@appTypes/PagesApi.interface'

import useWindowSize from '@hooks/useWindowSize'

import BannerOffer from '@components/BannerOffer'
import BannerStack from '@components/BannerStack'
import CardProductDetailHighlight from '@components/CardProductDetailHighlight'
import CardTableBulletList from '@components/CardTableBulletList'
import CardTableContentBase from '@components/CardTableContentBase'
import CardTableImage from '@components/CardTableImage'
import CardTableItemProductDetail from '@components/CardTableItemProductDetail'
import CreditCardContent from '@components/CreditCardContent'
import EligibilityIndicator from '@components/EligibilityIndicator'
import { getHasOfferTypeContent } from '@components/GuaranteedOfferModal/GuaranteedOfferModal'
import GuaranteedOfferQuestion from '@components/GuaranteedOfferQuestion'
import PreApprovedIndicator from '@components/PreApprovedIndicator'
import RepresentativeExample from '@components/RepresentativeExample'
import TmExclusiveIcon from '@components/TmExclusiveIcon'
import TotallySureBannerOffer from '@components/TotallySureBannerOffer'

import { getIsMinCreditLimitExpired } from '../../utils/getIsMinCreditLimitExpired'
import { getShowBullets } from '../../utils/getShowBullets'
import BestMatchRibbon from './components/BestMatchRibbon'
import * as Layout from './components/ScoredTableItemLayout'
import { getCategoryBadgeText } from './utils/getCategoryBadgeText'

interface ScoredTableItemProps {
    category: CreditCardProductCategory
    item: ScoredCreditCardProduct
    isBestMatch: boolean
    renderButtons: (item: ScoredCreditCardProduct) => React.ReactNode
    onShowBestMatchModal: () => void
    onShowPreApprovedModal: () => void
    onShowRepresentativeExampleModal: () => void
    onShowGuaranteedOfferModal: () => void
    onShowCreditLimitModal?: () => void
    onShowMinimumCreditLimitModal?: () => void
    minCreditLimitData?: GetGuaranteedCreditLimitResponse | null
}

const ScoredTableItem = ({
    category,
    item,
    isBestMatch = false,
    renderButtons,
    onShowBestMatchModal,
    onShowPreApprovedModal,
    onShowRepresentativeExampleModal,
    onShowGuaranteedOfferModal,
    onShowCreditLimitModal,
    onShowMinimumCreditLimitModal,
    minCreditLimitData,
}: ScoredTableItemProps) => {
    const showBullets = getShowBullets(category)

    const categoryBadgeText =
        item.productCategories && category
            ? getCategoryBadgeText(item.productCategories, category)
            : ''

    const isExclusiveOffer = item.offerType === OfferType.TMExclusive
    const isMarketWideOffer = item.offerType === OfferType.MarketWide

    const highlightState = getCardProductDetailHighlightState(item)

    const guaranteedOfferType = getGuaranteedOfferType({
        creditCard: item,
    })

    const isMinCreditLimitExpired = getIsMinCreditLimitExpired(
        minCreditLimitData?.isExpired
    )

    const windowSize = useWindowSize()

    const isMarketingOffer =
        guaranteedOfferType === GuaranteedOfferType.MarketingOffer

    const isCreditLimitGuaranteed = [
        GuaranteedOfferType.GuaranteedCreditLimit,
        GuaranteedOfferType.GuaranteedAprAndCreditLimit,
        GuaranteedOfferType.PreApprovedAndGuaranteedCreditLimit,
    ].includes(guaranteedOfferType!)

    return (
        <>
            {isExclusiveOffer ? (
                <BannerStack>
                    <BannerOffer
                        prefix={<TmExclusiveIcon />}
                        title={item.offerHeading}
                        description={item.offerCopy}
                        backgroundColor={theme.indigo30}
                        variant="backdrop"
                    />
                </BannerStack>
            ) : null}

            {isMarketWideOffer ? (
                <BannerStack>
                    <BannerOffer
                        title={item.offerHeading}
                        description={item.offerCopy}
                        backgroundColor={theme.infoLightBlue}
                        variant="backdrop"
                    />
                </BannerStack>
            ) : null}

            {isMarketingOffer ? (
                <BannerStack>
                    <TotallySureBannerOffer
                        id={`totally-sure-banner-offer-${item.productCode}`}
                        title="TotallySure"
                        description={
                            (windowSize?.width ?? 0) >= 1024
                                ? 'Be TotallySure with pre-approval, a guaranteed credit limit and a guaranteed rate (subject to final checks).'
                                : ''
                        }
                        onTooltipPress={() => onShowGuaranteedOfferModal()}
                    />
                </BannerStack>
            ) : null}

            {isCreditLimitGuaranteed ? (
                <BannerStack>
                    <BannerOffer
                        title="Credit limit guaranteed"
                        description=""
                        backgroundColor={theme.eligibilityLevel0}
                        variant="gcl"
                    />
                </BannerStack>
            ) : null}

            <Layout.ItemBase
                productCode={item.productCode}
                isBestMatch={isBestMatch}
            >
                {isBestMatch || highlightState ? (
                    <Box
                        display="flex"
                        flexDirection={['column', 'row']}
                        mb={['space20', isBestMatch ? 'space10' : 'space30']}
                    >
                        {isBestMatch ? (
                            <Box
                                ml={[
                                    `calc(-${theme.space40} - 2px)`,
                                    `calc(-${theme.space50} - 2px)`,
                                    `calc(-${theme.space50} - 2px)`,
                                ]}
                                mr="space20"
                            >
                                <BestMatchRibbon
                                    onTooltipPress={onShowBestMatchModal}
                                />
                            </Box>
                        ) : null}
                        {isMinCreditLimitExpired ? (
                            <Box>
                                <CardProductDetailHighlight
                                    highlightState={highlightState}
                                />
                            </Box>
                        ) : null}
                    </Box>
                ) : null}

                <Layout.ItemWrapper>
                    <Layout.ItemHeading>
                        <Heading as="h2" variant="60">
                            {item.name}
                        </Heading>
                    </Layout.ItemHeading>

                    <Layout.ItemImage>
                        <CardTableImage
                            cmsImageUrl={item.imageUrlLarge}
                            name={item.name}
                            isPortraitImage={getIsProductPortraitImage(
                                item.productCode
                            )}
                        />
                    </Layout.ItemImage>

                    <Layout.ItemIndicatorContent>
                        {!item.isPreApproved &&
                        item.score > 0 &&
                        item.score < 10 ? (
                            <Box
                                maxWidth={['220px', 'unset']}
                                display="flex"
                                justifyContent={'center'}
                            >
                                <EligibilityIndicator score={item.score * 10} />
                            </Box>
                        ) : null}

                        {item.isPreApproved ? (
                            <PreApprovedIndicator
                                id={item.productCode}
                                apr={getCardApr(item)}
                                onPress={onShowPreApprovedModal}
                            />
                        ) : null}
                    </Layout.ItemIndicatorContent>

                    <Layout.ItemContent>
                        {categoryBadgeText ? (
                            <Box mb="space20">
                                <Badge
                                    text={categoryBadgeText}
                                    uppercase={false}
                                    size="medium"
                                    textColor="dark"
                                    backgroundColor={theme.indigo20}
                                    renderIcon={iconProps => (
                                        <Tick
                                            {...iconProps}
                                            color={theme.indigo60}
                                            title={`Tick for category badge - ${item.productCode}`}
                                        />
                                    )}
                                />
                            </Box>
                        ) : null}

                        <Box
                            display={['block', 'flex']}
                            justifyContent={['unset', 'flex-start']}
                            flexDirection={['column', 'row']}
                        >
                            <CardTableContentBase
                                hasGuaranteedBadge={
                                    isMarketingOffer || isCreditLimitGuaranteed
                                }
                            >
                                <CreditCardContent
                                    category={category}
                                    creditCard={item}
                                    onShowCreditLimitModal={
                                        onShowCreditLimitModal
                                    }
                                    onShowMinimumCreditLimitModal={
                                        onShowMinimumCreditLimitModal
                                    }
                                    minCreditLimitData={minCreditLimitData}
                                    renderProductDetail={details => {
                                        if (details.value === 'N/A') {
                                            return <></>
                                        }
                                        return (
                                            <CardTableItemProductDetail
                                                {...details}
                                            />
                                        )
                                    }}
                                />
                            </CardTableContentBase>

                            {showBullets ? (
                                <Box mb={['space30', 0, 0]} ml={[0, 'auto']}>
                                    <CardTableBulletList
                                        items={
                                            item.bulletsPositive?.slice(0, 4) ??
                                            []
                                        }
                                        balanceTransferComboLimit={
                                            item.balanceTransferComboLimit
                                        }
                                        balanceTransferPeriodMonths={
                                            item.balanceTransferOfferDurationMonths
                                        }
                                        guaranteedCreditLimit={
                                            item.guaranteedRate
                                                .guaranteedCreditLimit
                                        }
                                    />
                                </Box>
                            ) : null}
                        </Box>
                    </Layout.ItemContent>

                    {guaranteedOfferType !==
                        GuaranteedOfferType.MarketingOffer &&
                    getHasOfferTypeContent(guaranteedOfferType) ? (
                        <Layout.ItemGuaranteedOfferQuestion>
                            <GuaranteedOfferQuestion
                                offerType={guaranteedOfferType!}
                                onTooltipPress={() =>
                                    onShowGuaranteedOfferModal()
                                }
                            />
                        </Layout.ItemGuaranteedOfferQuestion>
                    ) : null}

                    <Layout.ItemButtons>
                        {renderButtons(item)}
                    </Layout.ItemButtons>
                </Layout.ItemWrapper>

                <Layout.ItemFooter>
                    <RepresentativeExample
                        item={item}
                        onTooltipPress={onShowRepresentativeExampleModal}
                    />
                </Layout.ItemFooter>
            </Layout.ItemBase>
        </>
    )
}

export default ScoredTableItem
