import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

import {
    categorySortKeyComparatorKeys,
    sorterDisplayNames,
} from './categorySortKeyComparatorKeys'

export const getCategorySortKeysToShow = (
    category: CreditCardProductCategory
): Record<string, string> => {
    const sortKeys = Object.keys(categorySortKeyComparatorKeys[category])
    const newSortKeys = sortKeys.reduce(
        (sortKey, key) => ({
            ...sortKey,
            [key]: sorterDisplayNames[key as keyof typeof sorterDisplayNames],
        }),
        {}
    )
    return newSortKeys
}
