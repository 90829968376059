import { captureException } from '@sentry/nextjs'
import { useEffect, useMemo } from 'react'

import getCategoryByCategoryId from '@utils/getCategoryByCategoryId'

import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

import useAppQueryState from '@hooks/useAppQueryState'

import { useAlternativeProductCategoriesQuery } from '@queries/useAlternativeProductCategoriesQuery'
import { useHasEverUsedNativeAppQuery } from '@queries/useHasEverUsedNativeAppQuery'
import { useMinimumGuaranteedCreditLimitQuery } from '@queries/useMinimumGuaranteedCreditLimitQuery'
import { useScoredCategoriesQuery } from '@queries/useScoredCategoriesQuery'

import AnimatedLoadingPage from '@components/AnimatedLoadingPage'
import ErrorBoundary from '@components/ErrorBoundary'
import TermDescriptions from '@components/TermDescriptions'

import ScoredTable from './components/ScoredTable/ScoredTable'
import getHighlightCard from './components/ScoredTable/utils/getHighlightCard'
import ScoredTableError from './components/ScoredTableError'
import ScoredTableSkeleton from './components/ScoredTableSkeleton/ScoredTableSkeleton'
import useGetDifferenceInNewCreditCardsOffers from './hooks/useGetDifferenceInNewCreditCardsOffers'
import getRedirectToAlternativeProducts from './utils/getRedirectToAlternativeProducts'

const ScoredTableRoute = () => {
    const { appQueryState, goToRouteWithAppState } = useAppQueryState()

    const scoredCategoriesResponse = useScoredCategoriesQuery({
        body: {
            scoreId: appQueryState.scoreId!,
            customerId: appQueryState.customerId!,
            category: appQueryState.categoryId!,
        },
        select: data => data,
    })

    const shouldFetchAlternativeProducts = getRedirectToAlternativeProducts(
        scoredCategoriesResponse
    )

    const minCreditLimitResponse = useMinimumGuaranteedCreditLimitQuery({
        customerId: appQueryState.customerId!,
    })

    const hasUsedNativeAppResponse = useHasEverUsedNativeAppQuery({
        customerId: appQueryState.customerId!,
        shouldFetch: appQueryState.showAppPromotion === 'true',
    })

    const altProductsResponse = useAlternativeProductCategoriesQuery({
        body: {
            scoreId: appQueryState.scoreId!,
            customerId: appQueryState.customerId!,
        },
        shouldFetchAlternativeProducts,
    })

    const highlightCard = getHighlightCard({
        category: CreditCardProductCategory.BestCards,
        highlightCardPath: appQueryState.isCardSpecific
            ? appQueryState.scoredCardUrl
            : undefined,
        scoredCategories: scoredCategoriesResponse.data?.scoredCategories,
        allActiveCards: scoredCategoriesResponse.data?.allActiveCards,
    })

    useEffect(() => {
        if (altProductsResponse.isSuccess) {
            goToRouteWithAppState('alternative-products')
        }
    }, [altProductsResponse.isSuccess, goToRouteWithAppState])

    const activeCategoryId = useMemo(() => {
        if (
            scoredCategoriesResponse.isSuccess &&
            getCategoryByCategoryId(
                scoredCategoriesResponse.data.scoredCategories,
                appQueryState.categoryId!
            ) == null
        ) {
            return CreditCardProductCategory.BestCards
        }
        return appQueryState.categoryId!
    }, [scoredCategoriesResponse.isSuccess])

    switch (true) {
        case scoredCategoriesResponse.isLoading ||
            (shouldFetchAlternativeProducts && altProductsResponse.isLoading) ||
            altProductsResponse.isSuccess: {
            return (
                <>
                    <AnimatedLoadingPage fill={false} />
                    <ScoredTableSkeleton />
                </>
            )
        }
        case scoredCategoriesResponse.isSuccess &&
            scoredCategoriesResponse.data != null: {
            return (
                <ErrorBoundary
                    fallback={<ScoredTableError />}
                    onError={({ error }) => captureException(error)}
                >
                    <ScoredTable
                        highlightCard={highlightCard}
                        activeCategoryId={activeCategoryId}
                        scoredCategories={
                            scoredCategoriesResponse.data?.scoredCategories!
                        }
                        scoredExperiments={
                            scoredCategoriesResponse.data?.experiments
                        }
                        thirdPartyReference={
                            scoredCategoriesResponse.data?.thirdPartyReference
                        }
                        hasEverUsedNativeApp={
                            hasUsedNativeAppResponse.data?.hasEverUsedNativeApp
                        }
                        minCreditLimitData={minCreditLimitResponse?.data}
                    />
                    <TermDescriptions
                        showMatchFactorDescription={true}
                        showCreditCardTypesDescription={true}
                        showPreApprovedDescription={true}
                        showExclusiveDescription={true}
                        showVariableAprDescription={true}
                        showPrePopulation={true}
                        showSeeParticularCardDescription={true}
                        showPostCaptureGuaranteedCreditLimitDescription={
                            minCreditLimitResponse?.data?.isExpired === false
                        }
                    />
                </ErrorBoundary>
            )
        }
        case scoredCategoriesResponse.isError:
        default: {
            return <ScoredTableError error={scoredCategoriesResponse.error} />
        }
    }
}

export default ScoredTableRoute
