import Box from '@totallymoney/ui/components/Box'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'
import React from 'react'

import Ribbon from '@components/Ribbon'
import TooltipButton from '@components/TooltipButton'

interface BestMatchRibbonProps {
    borderOffset?: number
    onTooltipPress: () => void
}

const BestMatchRibbon = ({
    borderOffset,
    onTooltipPress,
}: BestMatchRibbonProps) => {
    return (
        <Ribbon borderOffset={borderOffset}>
            <Box
                as="span"
                mr="space10"
                display="flex"
                p="space025"
                pl={['space30', 'space40']}
            >
                <Box mr="space10">
                    <Text
                        color="white"
                        style={{ fontFamily: theme.buenosAires }}
                    >
                        Your Best Match
                    </Text>
                </Box>
                <TooltipButton
                    aria-label="What is my Best Match?"
                    color="white"
                    title="Tooltip icon for Best Match"
                    onPress={onTooltipPress}
                />
            </Box>
        </Ribbon>
    )
}

export default BestMatchRibbon
