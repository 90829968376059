import TotallyMoneySymbolOnly from '@totallymoney/ui/brandassets/logos/TotallyMoneySymbolOnly'
import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'
import LinkButton from '@totallymoney/ui/components/LinkButton/LinkButton'
import Text from '@totallymoney/ui/components/Text'
// @ts-ignore
import Cross from '@totallymoney/ui/icons/Cross'
import theme from '@totallymoney/ui/theme'
import React, { useState } from 'react'

import AppAdoptionHeaderBackground from '../../public/images/bg-squiggle.png'

const AppAdoptionHeader = ({ customerId }: { customerId?: string }) => {
    const [showAppAdoptionHeader, setShowAppAdoptionHeader] = useState(true)
    return showAppAdoptionHeader && customerId ? (
        <Box
            p="space30"
            pb="space20"
            backgroundColor={theme.productNeutral120}
            backgroundImage={`url(${AppAdoptionHeaderBackground?.src})`}
            style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
        >
            <Box display="flex" justifyContent="space-between" pb="space20">
                <Box width="space50">
                    <TotallyMoneySymbolOnly />
                </Box>
                <Box onClick={() => setShowAppAdoptionHeader(false)}>
                    <Cross size={20} />
                </Box>
            </Box>
            <Box pb="space20">
                <Heading variant="60">Switch to the app</Heading>
                <Text variant="100">
                    View all card offers available with our app.
                </Text>
            </Box>
            <LinkButton
                href={`https://totallymoney.app.link/rVTn5VO5dAb/?customerId=${customerId}`}
                variant="secondaryOutline"
                text="Open the app"
            />
        </Box>
    ) : null
}

export default AppAdoptionHeader
