import React, { ReactElement } from 'react'

import getGuaranteedOfferType from '@utils/getGuaranteedOfferType'

import {
    GuaranteedOfferType,
    ModalType,
    ScoredCreditCardProductsCategory,
} from '@appTypes/EligibilityApi.interface'

import BannerStack from '@components/BannerStack'
import TotallySureBannerOffer from '@components/TotallySureBannerOffer'

import {
    HighlightCard,
    HighlightCardStatus,
} from '../../utils/getHighlightCard'
import HighlightHeroEligible from './components/HighlightHeroEligible'
import HighlightHeroIneligible from './components/HighlightHeroIneligible/HighlightHeroIneligible'

interface CreditCardsTableHighlightHeroProps {
    highlightedCreditCard: HighlightCard
    applicationUrl: string | null
    onApplyButtonPress: () => void
    onSeeMoreDetailsPress: () => void
    onShowGuaranteedOfferModal: () => void
    onTooltipPress?: (modalType: ModalType) => void
    scoredCategories: ScoredCreditCardProductsCategory[]
}

const CreditCardsTableHighlightHero = ({
    applicationUrl,
    scoredCategories,
    highlightedCreditCard,
    onApplyButtonPress,
    onSeeMoreDetailsPress,
    onShowGuaranteedOfferModal,
    onTooltipPress,
}: CreditCardsTableHighlightHeroProps): ReactElement => {
    if (
        highlightedCreditCard.status === HighlightCardStatus.CardIneligible ||
        highlightedCreditCard.status === HighlightCardStatus.NoCard ||
        highlightedCreditCard.status === HighlightCardStatus.NoData
    ) {
        return (
            <HighlightHeroIneligible
                item={highlightedCreditCard.card}
                totalCreditCards={scoredCategories[0].totalCreditCards}
            />
        )
    }

    const card = highlightedCreditCard.card

    const guaranteedOfferType = getGuaranteedOfferType({
        creditCard: card,
    })

    const isMarketingOffer =
        guaranteedOfferType === GuaranteedOfferType.MarketingOffer

    return (
        <>
            {isMarketingOffer ? (
                <BannerStack>
                    <TotallySureBannerOffer
                        id={`totally-sure-banner-offer-${card.productCode}`}
                        title="TotallySure"
                        onTooltipPress={onShowGuaranteedOfferModal}
                    />
                </BannerStack>
            ) : null}

            <HighlightHeroEligible
                item={card}
                applicationUrl={applicationUrl!}
                onApplyPress={onApplyButtonPress}
                onSeeMoreDetailsPress={onSeeMoreDetailsPress}
                onShowPreApprovedModal={() =>
                    onTooltipPress?.(ModalType.PreApprovedModal)
                }
            />
        </>
    )
}

export default CreditCardsTableHighlightHero
