import React from 'react'

import { StyledCardTableContentBase } from './CardTableContentBase.styled'

interface ContentBaseProps {
    hasGuaranteedBadge?: boolean
    children: React.ReactNode
}

const CardTableContentBase = ({
    hasGuaranteedBadge,
    children,
}: ContentBaseProps) => {
    return (
        <StyledCardTableContentBase
            display="flex"
            flexDirection={['column', 'row']}
            maxHeight={['unset', '150px']}
            mb={[0, hasGuaranteedBadge ? 'space15' : 0]}
        >
            {children}
        </StyledCardTableContentBase>
    )
}

export default CardTableContentBase
