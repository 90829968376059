import Box from '@totallymoney/ui/components/Box'
import Image from 'next/legacy/image'
import React from 'react'

import backgroundIntroGclModal from '../../../public/images/bg-intro-gcl-modal.png'

const BackgroundBlob = React.memo(function Blob() {
    return (
        <Box
            top={0}
            left={0}
            zIndex={-1}
            bottom={0}
            position="absolute"
            overflow="hidden"
            borderRadius="borderRadius20"
            width="100%"
        >
            <Image
                src={backgroundIntroGclModal}
                layout="fill"
                alt="Background blob"
            />
        </Box>
    )
})

export default BackgroundBlob
