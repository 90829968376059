import { useState } from 'react'

import useAppQueryState from '@hooks/useAppQueryState'
import useFeatureFlagContext from '@hooks/useFeatureFlagContext'

import { useMinimumGuaranteedCreditLimitQuery } from '@queries/useMinimumGuaranteedCreditLimitQuery'

import ScoredTableRoute from '@routes/ScoredTableRoute/ScoredTableRoute'
import ScoredTableSkeleton from '@routes/ScoredTableRoute/components/ScoredTableSkeleton'

import BackgroundShadowPage from '@components/BackgroundShadowPage'

import GuaranteedCreditLimitIntroModal from './components/GuaranteedCreditLimitIntroModal'

const GuaranteedCreditLimitIntroRoute = () => {
    const [showScoredTable, setShowScoredTable] = useState(false)
    const { appQueryState } = useAppQueryState()

    const minimumGuaranteedCreditLimitResponse =
        useMinimumGuaranteedCreditLimitQuery({
            customerId: appQueryState.customerId!,
        })

    const featureFlagContext = useFeatureFlagContext()

    if (
        minimumGuaranteedCreditLimitResponse.isLoading ||
        featureFlagContext.isLoading
    ) {
        return (
            <>
                <BackgroundShadowPage />
                <ScoredTableSkeleton />
            </>
        )
    }

    if (
        !showScoredTable &&
        (minimumGuaranteedCreditLimitResponse.data === null ||
            minimumGuaranteedCreditLimitResponse.data?.isExpired === true)
    ) {
        return (
            <>
                <ScoredTableSkeleton />
                <GuaranteedCreditLimitIntroModal
                    minCreditLimitData={
                        minimumGuaranteedCreditLimitResponse.data
                    }
                    onCloseModal={() => setShowScoredTable(true)}
                />
            </>
        )
    }

    return <ScoredTableRoute />
}

export default GuaranteedCreditLimitIntroRoute
