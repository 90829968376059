import Box from '@totallymoney/ui/components/Box'
import Button from '@totallymoney/ui/components/Button'
import Text from '@totallymoney/ui/components/Text'

interface CardTablePaginationProps {
    numberOfCardsToShow: number
    totalCreditCards: number
    showMoreResultsButton: boolean
    onShowMoreResults: () => void
}

const CardTablePagination = ({
    numberOfCardsToShow,
    totalCreditCards,
    showMoreResultsButton,
    onShowMoreResults,
}: CardTablePaginationProps) => {
    return (
        <>
            {showMoreResultsButton ? (
                <Box display="flex" justifyContent="center" mb="space20">
                    <Button
                        variant="secondaryOutline"
                        onClick={onShowMoreResults}
                    >
                        Show more results
                    </Button>
                </Box>
            ) : null}
            <Box display="flex" justifyContent="flex-end">
                <Text variant="80">
                    Showing{' '}
                    <strong>
                        {numberOfCardsToShow > totalCreditCards
                            ? totalCreditCards
                            : numberOfCardsToShow}
                    </strong>{' '}
                    of <strong>{totalCreditCards}</strong> cards
                </Text>
            </Box>
        </>
    )
}

export default CardTablePagination
