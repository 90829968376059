import Box from '@totallymoney/ui/components/Box'
import theme from '@totallymoney/ui/theme'
import styled from 'styled-components'

import { StyledCardTableItemProductDetail } from '@components/CardTableItemProductDetail/CardTableItemProductDetail.styled'

export const StyledCardTableContentBase = styled(Box)`
    gap: ${theme.space10};

    ${StyledCardTableItemProductDetail} + ${StyledCardTableItemProductDetail} {
        padding-top: ${theme.space10};

        border-top: 1px solid ${theme.greyLighter};
    }

    ${theme.mediaMediumPlus`
        gap: ${theme.space20};

        ${StyledCardTableItemProductDetail} + ${StyledCardTableItemProductDetail} {
            padding: 0 0 0 ${theme.space20};

            border-left: 1px solid ${theme.greyLighter};
            border-top: 0;
        }
    `}
`
