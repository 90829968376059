import Badge from '@totallymoney/ui/components/Badge'
import Box from '@totallymoney/ui/components/Box'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'
import * as React from 'react'
import styled from 'styled-components'

import {
    ProductDetail,
    ProductDetailType,
} from '@components/CreditCardContent/components/Content.interface'

import { StyledCardTableItemProductDetail } from './CardTableItemProductDetail.styled'

const TextValue = styled(Text)`
    text-align: right;

    ${theme.mediaMediumPlus`
        text-align: left;
    `}
`

const CardTableItemProductDetail = ({
    title,
    value,
    detailType,
}: ProductDetail) => {
    const showGuaranteedBadge =
        detailType === ProductDetailType.GuaranteedExample ||
        detailType === ProductDetailType.GuaranteedCreditLimit

    return (
        <StyledCardTableItemProductDetail
            display="flex"
            flexDirection={['unset', 'column']}
            alignItems={['center', 'flex-start']}
            justifyContent="space-between"
        >
            <Box mb={[0, 'space10']}>
                {typeof title === 'string' ? (
                    <Text variant="100" color="light">
                        {title}
                    </Text>
                ) : (
                    title
                )}
            </Box>
            <Box>
                {typeof value === 'string' ? (
                    <TextValue
                        variant="120"
                        weight="bold"
                        textAlign="right"
                        color="light"
                        style={{
                            fontFamily: theme.buenosAires,
                            paddingLeft:
                                (window?.innerWidth ?? 0) < 700 ? 'space05' : 0,
                        }}
                    >
                        {value}
                    </TextValue>
                ) : (
                    value
                )}
                {showGuaranteedBadge ? (
                    <Box
                        position={['relative', 'absolute']}
                        mt="space05"
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <Badge
                            text="Guaranteed"
                            uppercase={false}
                            backgroundColor={theme.preApprovedGreen}
                        />
                    </Box>
                ) : null}
            </Box>
        </StyledCardTableItemProductDetail>
    )
}

export default CardTableItemProductDetail
