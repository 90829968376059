import { GuaranteedOfferType } from '@appTypes/App.interface'
import { ScoredCreditCardProduct } from '@appTypes/EligibilityApi.interface'

import getGuaranteedOfferType from './getGuaranteedOfferType'
import { getRepresentativeExampleTextForCard } from './getRepresentativeExampleTextForCard'
import { getSanitisedCardName } from './getSanitisedCardName'

type CreditCardEventData = {
    CardDetailsUrl: string
    ChanceOfAcceptance: string
    HasGuaranteedApr: boolean
    HasGuaranteedCreditLimit: boolean
    ImageUrl: string
    ImageUrlMedium: string
    LoanId: string
    MatchFactor: number
    MonthlyPayment: number
    Name: string
    OfferDescription: string
    OfferHeading: string
    OfferType: string
    Path: string
    RepExampleText: string
    RepresentativeAPR: string
}

type IndexedCreditCardsEventData<T, Index extends number> = {
    [K in keyof T & string as `card${Index}${K}`]: T[K]
}

export interface TopCreditCardsEventData
    extends IndexedCreditCardsEventData<
        Partial<CreditCardEventData>,
        1 | 2 | 3
    > {
    numberOfEligibleProducts: number
}

const getCreditCardEventData = <DisplayIndex extends number>(
    creditCard: ScoredCreditCardProduct,
    displayIndex: DisplayIndex
): IndexedCreditCardsEventData<CreditCardEventData, DisplayIndex> => {
    const cardPrefixKey = `card${displayIndex}`
    const offerDetail = creditCard.offerDetail

    const guaranteedOfferType = getGuaranteedOfferType({
        creditCard,
    })

    return {
        [`${cardPrefixKey}Name`]: creditCard.name,
        [`${cardPrefixKey}RepresentativeAPR`]: offerDetail.representativeAPR,
        [`${cardPrefixKey}CardDetailsUrl`]: creditCard.cardDetailsUrl,
        [`${cardPrefixKey}ChanceOfAcceptance`]: `${creditCard.score * 10}%`,
        [`${cardPrefixKey}MatchFactor`]: creditCard.categoryScore,
        [`${cardPrefixKey}ImageUrlMedium`]: creditCard.imageUrlMedium,
        [`${cardPrefixKey}RepExampleText`]:
            getRepresentativeExampleTextForCard(creditCard),
        [`${cardPrefixKey}Path`]: getSanitisedCardName(creditCard.name),
        [`${cardPrefixKey}OfferDescription`]: creditCard.offerCopy,
        [`${cardPrefixKey}OfferHeading`]: creditCard.offerHeading,
        [`${cardPrefixKey}OfferType`]: creditCard.offerType ?? 'No Offer',
        [`${cardPrefixKey}GuaranteedApr`]:
            creditCard.guaranteedRate.guaranteedAPR,
        [`${cardPrefixKey}HasGuaranteedApr`]:
            creditCard.guaranteedRate.guaranteedAPR != null,
        [`${cardPrefixKey}HasGuaranteedCreditLimit`]:
            creditCard.guaranteedRate.guaranteedCreditLimit != null,
        [`${cardPrefixKey}GuaranteedCreditLimit`]:
            creditCard.guaranteedRate.guaranteedCreditLimit,
        [`${cardPrefixKey}HasMarketingOffer`]:
            guaranteedOfferType === GuaranteedOfferType.MarketingOffer,
    } as any
}

const getTopCreditCardsEventData = (creditCards: ScoredCreditCardProduct[]) => {
    if (creditCards.length === 0) {
        return null
    }
    const topThreeCards = creditCards.slice(0, 3)

    const eventData = {
        numberOfEligibleProducts: creditCards.length,
    } as TopCreditCardsEventData

    return topThreeCards.reduce((data, loan, index) => {
        return {
            ...data,
            ...getCreditCardEventData(loan, index + 1),
        }
    }, eventData)
}

export default getTopCreditCardsEventData
