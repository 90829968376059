import {
    CreditCardProductCategory,
    ScoredCreditCardProductCategory,
} from '@appTypes/EligibilityApi.interface'

export function getCategoryBadgeText(
    cardCategories: ScoredCreditCardProductCategory[],
    category: CreditCardProductCategory
) {
    if (category !== CreditCardProductCategory.BestCards) {
        return null
    }
    const activeCategory = cardCategories.find(cardCategory =>
        [
            CreditCardProductCategory.Rewards,
            CreditCardProductCategory.Cashback,
        ].includes(cardCategory.name as CreditCardProductCategory)
    )
    switch (activeCategory?.name) {
        case CreditCardProductCategory.Rewards:
            return 'Rewards'
        case CreditCardProductCategory.Cashback:
            return 'Cashback'
        default:
            return null
    }
}
