import Box from '@totallymoney/ui/components/Box'
import Text from '@totallymoney/ui/components/Text'
import EditPencil from '@totallymoney/ui/icons/EditPencil'
import theme from '@totallymoney/ui/theme'

import formatNumberToCurrency from '@utils/formatNumberToCurrency'

import useAppQueryState from '@hooks/useAppQueryState'

import { useMinimumGuaranteedCreditLimitQuery } from '@queries/useMinimumGuaranteedCreditLimitQuery'

import { MinimumCreditLimitUpdateWrapper } from './MinimumCreditLimitUpdate.styled'

interface MinimumCreditLimitUpdateProps {
    onShowMinimumCreditLimitModal: () => void
}

const MinimumCreditLimitUpdate = ({
    onShowMinimumCreditLimitModal,
}: MinimumCreditLimitUpdateProps) => {
    const { appQueryState } = useAppQueryState()

    const { data } = useMinimumGuaranteedCreditLimitQuery({
        customerId: appQueryState.customerId!,
    })

    return (
        <MinimumCreditLimitUpdateWrapper
            onClick={() => onShowMinimumCreditLimitModal()}
        >
            {data?.minimumGuaranteedCreditLimit != null &&
            data?.isExpired === false ? (
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box pr="space10" as="span">
                        Min. credit limit
                    </Box>
                    <Box
                        pr="space20"
                        alignItems="center"
                        display="flex"
                        mt="space025"
                    >
                        <Text
                            variant="80"
                            data-testid="minCreditLimitUpdateAmount"
                        >
                            {formatNumberToCurrency(
                                data.minimumGuaranteedCreditLimit
                            )}
                        </Text>
                    </Box>
                </Box>
            ) : (
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box pr="space10" as="span">
                        Add minimum credit limit
                    </Box>
                </Box>
            )}
            <Box mr="space10">
                <EditPencil size={16} color={theme.indigo100} />
            </Box>
        </MinimumCreditLimitUpdateWrapper>
    )
}

export default MinimumCreditLimitUpdate
