import Heading from '@totallymoney/ui/components/Heading'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'

import { getDisplayScore } from '@utils/getDisplayScore'
import { getEligibilityArticle } from '@utils/getElgiibilityArticle'

import { ScoredCreditCardProduct } from '@appTypes/EligibilityApi.interface'

import { getSemanticEligibilityScore } from '@components/EligibilityIndicator/utils/getSemanticEligibilityScore'

export function getEligibleContent(card: ScoredCreditCardProduct) {
    const semanticScore = getSemanticEligibilityScore(
        getDisplayScore(card.score)
    )

    if (card.isPreApproved) {
        return (
            <Stack space="space10">
                <Heading variant="40">Congratulations!</Heading>

                <Text variant="120">
                    You have been pre-approved for this card. Click to start
                    your application.
                </Text>
            </Stack>
        )
    }

    if (card.score >= 4) {
        return (
            <Text>
                You have {getEligibilityArticle(semanticScore)}{' '}
                {semanticScore?.toLowerCase()} chance of being accepted for{' '}
                <strong>{card.name}</strong>.
            </Text>
        )
    }

    return <></>
}
