import { captureException } from '@sentry/nextjs'
import { useEffect, useMemo, useState } from 'react'

import getCategoryByCategoryId from '@utils/getCategoryByCategoryId'
import {
    LocalStorageItem,
    getLocalStorageItem,
    setLocalStorageItem,
} from '@utils/localStorage'

import {
    CreditCardProductCategory,
    ScoredCreditCardProductsCategory,
} from '@appTypes/EligibilityApi.interface'

import useAppQueryState from '@hooks/useAppQueryState'

interface PreviousCreditCardOffers {
    customerId: string
    previousCreditCardOffers: number
}

const useGetDifferenceInNewCreditCardsOffers = (
    data: ScoredCreditCardProductsCategory[]
): number => {
    const { appQueryState } = useAppQueryState()

    const { totalCreditCards } = getCategoryByCategoryId(
        data,
        CreditCardProductCategory.BestCards
    ) ?? { totalCreditCards: 0 }

    const differenceInNewCreditCardsOffers = useMemo(() => {
        try {
            const localStorageCardOffers = getLocalStorageItem(
                LocalStorageItem.previousCreditCardOffers
            )

            if (localStorageCardOffers) {
                const parsedLocalStorageCardOffers = JSON.parse(
                    localStorageCardOffers
                ) as PreviousCreditCardOffers

                if (
                    parsedLocalStorageCardOffers?.customerId !==
                    appQueryState.customerId
                ) {
                    return null
                }

                if (
                    totalCreditCards >
                    parsedLocalStorageCardOffers.previousCreditCardOffers
                ) {
                    return (
                        totalCreditCards -
                        parsedLocalStorageCardOffers.previousCreditCardOffers
                    )
                }
            }

            return 0
        } catch (err) {
            captureException(err, { tags: { newOffers: true } })
            return 0
        }
    }, [totalCreditCards, appQueryState.customerId])

    useEffect(() => {
        if (differenceInNewCreditCardsOffers != null) {
            setLocalStorageItem(
                LocalStorageItem.previousCreditCardOffers,
                JSON.stringify({
                    customerId: appQueryState.customerId!,
                    previousCreditCardOffers: totalCreditCards,
                } satisfies PreviousCreditCardOffers)
            )
        }
    }, [
        differenceInNewCreditCardsOffers,
        totalCreditCards,
        appQueryState.customerId,
    ])

    return differenceInNewCreditCardsOffers ?? 0
}

export default useGetDifferenceInNewCreditCardsOffers
