import {
    CreditCardProductCategory,
    ScoredCreditCardProductsCategory,
} from '@appTypes/EligibilityApi.interface'

function getCategoryByCategoryId(
    scoredCategories: ScoredCreditCardProductsCategory[],
    categoryId: CreditCardProductCategory
) {
    return scoredCategories?.find(category => category.id === categoryId)
}

export default getCategoryByCategoryId
