import dynamic from 'next/dynamic'

const DynamicFrequentlyAskedQuestions = dynamic(
    () => import('./FrequentlyAskedQuestions'),
    {
        loading: () => <p>Loading...</p>,
    }
)

export default DynamicFrequentlyAskedQuestions
