import { UseQueryResult } from '@tanstack/react-query'

import getCategoryByCategoryId from '@utils/getCategoryByCategoryId'

import {
    CreditCardProductCategory,
    FetchScoredCreditCardProductsResponse,
} from '@appTypes/EligibilityApi.interface'

function getRedirectToAlternativeProducts(
    scoredCategoriesResponse: UseQueryResult<
        FetchScoredCreditCardProductsResponse,
        unknown
    >
): boolean {
    if (
        scoredCategoriesResponse.isSuccess &&
        !scoredCategoriesResponse.isLoading
    ) {
        const totalCardsCount =
            getCategoryByCategoryId(
                scoredCategoriesResponse.data?.scoredCategories ?? [],
                CreditCardProductCategory.BestCards
            )?.totalCreditCards ?? 0

        return totalCardsCount === 0
    }
    return false
}

export default getRedirectToAlternativeProducts
