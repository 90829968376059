import { SemanticEligibilityScore } from '@components/EligibilityIndicator/utils/getSemanticEligibilityScore'

export function getEligibilityArticle(
    semanticScore: SemanticEligibilityScore | null
): 'a' | 'an' {
    switch (semanticScore) {
        case SemanticEligibilityScore.Poor:
        case SemanticEligibilityScore.Good:
        case SemanticEligibilityScore.VeryGood:
            return 'a'
        case SemanticEligibilityScore.Average:
        case SemanticEligibilityScore.Excellent:
            return 'an'
        default:
            return 'a'
    }
}
