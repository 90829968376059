import Box from '@totallymoney/ui/components/Box'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'

import type { HighlightState } from '@utils/getCardProductDetailHighlightState'

const highlightContent = {
    guaranteedApr: {
        heading: 'Guaranteed APR',
        text: 'You are guaranteed to get the APR shown if you’re accepted',
    },
    guaranteedCreditLimit: {
        heading: 'Guaranteed credit limit',
        text: 'You are guaranteed to get the credit limit shown if you’re accepted',
    },
    combined: {
        heading: 'Guaranteed APR & credit limit',
        text: 'You are guaranteed to get the APR and credit limit shown if you’re accepted',
    },
} as const

interface CardProductDetailHighlightProps {
    highlightState: HighlightState
}

const CardProductDetailHighlight = ({
    highlightState,
}: CardProductDetailHighlightProps) => {
    if (highlightState == null) {
        return null
    }

    const content = highlightContent[highlightState]

    return (
        <Box
            borderRadius="borderRadius10"
            display="inline-flex"
            py="space10"
            px="space20"
            bg="eligibilityLevel0-bg10"
            justifyContent="center"
            flexDirection={['column', 'row']}
        >
            <Box mr="space10">
                <Text
                    variant="80"
                    style={{
                        fontFamily: theme.buenosAires,
                        color: theme.eligibilityLevel0,
                    }}
                >
                    {content.heading}
                </Text>
            </Box>
            <Text variant="80" color="light">
                {content.text}
            </Text>
        </Box>
    )
}

export default CardProductDetailHighlight
