import Box from '@totallymoney/ui/components/Box'

const BackgroundShadowPage = () => {
    return (
        <Box
            bg="rgba(8,5,28,0.8)"
            position="fixed"
            zIndex="5999"
            top="0"
            left="0"
            width="100%"
            height="100%"
        />
    )
}

export default BackgroundShadowPage
