import Box from '@totallymoney/ui/components/Box'
import Text from '@totallymoney/ui/components/Text'

const AdvertisementBanner = () => {
    return (
        <Box p="space10">
            <Text textAlign="center" variant="80" weight="bold">
                Advertisement
            </Text>
            <Text textAlign="center" variant="80">
                Your matches will continue below
            </Text>
        </Box>
    )
}

export default AdvertisementBanner
