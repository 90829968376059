import Box from '@totallymoney/ui/components/Box'
import Image from 'next/legacy/image'
import React from 'react'

interface CardTableImageProps {
    name: string
    cmsImageUrl: string
    isPortraitImage: boolean
}

const CardTableImage = ({
    name,
    cmsImageUrl,
    isPortraitImage,
}: CardTableImageProps) => {
    const imageDimensions = !isPortraitImage
        ? { width: 208, height: 132 }
        : { width: 132, height: 208 }

    return (
        <Box
            display="flex"
            width="100%"
            justifyContent={['center', 'flex-start']}
            maxWidth={['unset', '220px']}
        >
            <Image
                src={cmsImageUrl}
                alt={`${name} credit card`}
                {...imageDimensions}
            />
        </Box>
    )
}

export default CardTableImage
