import Box from '@totallymoney/ui/components/Box'
import theme from '@totallymoney/ui/theme'
import React, { ReactNode } from 'react'

function Ribbon({
    children,
    borderOffset,
}: {
    borderOffset?: number
    children: ReactNode
}) {
    return (
        <Box left={borderOffset}>
            <Box
                bg={theme.indigo100}
                py="space05"
                pr="space10"
                pl="space20"
                borderRadius="borderRadius10"
                alignItems="center"
                alignSelf="flex-start"
                display="inline-flex"
            >
                {children}
            </Box>
            <Box mt="-6px">
                <Box bg={theme.indigo100} width={8} height={12} />
                <Box overflow="hidden" width={8} height={16} mt="-6px">
                    <Box
                        borderTopLeftRadius={18}
                        borderBottomLeftRadius={18}
                        borderTopRightRadius={0}
                        width={16}
                        height={16}
                        bg={theme.indigo40}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default Ribbon
