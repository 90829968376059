import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

import {
    CategoryComparatorKey,
    categorySortKeyComparatorKeys,
    defaultSortKeys,
} from './categorySortKeyComparatorKeys'

export function getValidCategorySortKey({
    queryOption,
    categoryId,
}: {
    queryOption: CategoryComparatorKey | undefined
    categoryId: CreditCardProductCategory
}) {
    if (
        queryOption != null &&
        Object.keys(categorySortKeyComparatorKeys[categoryId]).includes(
            queryOption
        )
    ) {
        return queryOption
    }
    return defaultSortKeys[categoryId!]
}
