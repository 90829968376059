import Box from '@totallymoney/ui/components/Box'
import Button from '@totallymoney/ui/components/Button'
import Heading from '@totallymoney/ui/components/Heading'
import LinkButton from '@totallymoney/ui/components/LinkButton'
import Modal from '@totallymoney/ui/components/Modal'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'
import Cookies from 'js-cookie'

import useLogEvents from '@hooks/useLogEvents'

interface AppAdoptionModalProps {
    customerId?: string
    onModalClose: () => void
    showModal: boolean
}

const AppAdoptionModal = ({
    customerId,
    showModal,
    onModalClose,
}: AppAdoptionModalProps) => {
    const logEvents = useLogEvents()
    const appPromotionClick = () => {
        logEvents.logAppPromotionClick({
            promotion: 'creditCardsTable',
            source: 'cardsProductTable',
        })
        onModalClose()
    }

    return (
        <Modal
            hasHeader={false}
            title="See your offers in the app"
            isOpen={showModal}
            onClose={onModalClose}
            isFullScreen={false}
            hasMinHeight={false}
        >
            <Stack space="space10" pt="space30">
                <Heading variant="80">See your offers in the app</Heading>
                <Text variant="100">
                    For exclusive features and a better experience, switch over
                    to the app.
                </Text>
                <LinkButton
                    href={`https://totallymoney.app.link/rVTn5VO5dAb/?customerId=${customerId}`}
                    variant="primary"
                    onClick={appPromotionClick}
                    text="Open the app"
                />
                <Box display="flex" justifyContent="center">
                    <Button
                        variant="tertiaryTransparent"
                        onClick={() => {
                            onModalClose()
                            Cookies.set(
                                'appPromotionPreferWebToAppJourney',
                                'true'
                            )
                        }}
                    >
                        Continue on web
                    </Button>
                </Box>
            </Stack>
        </Modal>
    )
}

export default AppAdoptionModal
