import Box from '@totallymoney/ui/components/Box'
import Grid from '@totallymoney/ui/components/Grid'
// @ts-ignore
import AlertInformation from '@totallymoney/ui/icons/AlertInformation'
import { useEffect } from 'react'

import useLogEvents from '@hooks/useLogEvents'

import { KbaScenario } from '@config/kbaScenarioKeys'

import { Wrapper } from './KbaStatusBanner.styled'
import { KbaStatusMessage } from './components/KbaStatusMessage'

interface KbaStatusBannerProps {
    kbaScenario: KbaScenario
}

const KbaStatusBanner = ({ kbaScenario }: KbaStatusBannerProps) => {
    const logEvents = useLogEvents()

    useEffect(() => {
        logEvents.logBannerKbaDisplayEvent({ scenario: kbaScenario })
    }, [])

    const onKbaScenarioPress = (kbaScenario: KbaScenario) => {
        logEvents.logBannerKbaClickEvent({ scenario: kbaScenario })
    }

    return (
        <Grid>
            <Wrapper>
                <Box
                    display="flex"
                    bg="alertInfo100"
                    size="40px"
                    borderRadius="borderRadius10"
                    alignItems="center"
                    justifyContent="center"
                >
                    <AlertInformation size={18} accessibleTitle="Information" />
                </Box>
                <Box display="flex" alignItems="center">
                    <KbaStatusMessage
                        kbaScenario={kbaScenario}
                        onKbaScenarioPress={onKbaScenarioPress}
                    />
                </Box>
            </Wrapper>
        </Grid>
    )
}

export default KbaStatusBanner
