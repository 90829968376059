import Box from '@totallymoney/ui/components/Box'
import Modal from '@totallymoney/ui/components/Modal'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'

import UnorderedList, { ListItem } from '@components/UnorderedList'

interface BestMatchModalProps {
    onModalClose: () => void
    showModal: boolean
}

const BestMatchModal = ({ showModal, onModalClose }: BestMatchModalProps) => {
    return (
        <Modal
            title="What is my Best Match?"
            hasHeader={false}
            hasTooltipHeader={true}
            isOpen={showModal}
            onClose={onModalClose}
            isFullScreen={true}
            hasMinHeight={false}
        >
            <Stack space="space20">
                <Text variant="100">
                    Your Best Match is the card we think is the best on the
                    market for you. It’s recommended using our unique “Match
                    Factor” algorithm which takes into account:
                </Text>
                <Box ml="space20">
                    <UnorderedList>
                        <ListItem>Your profile</ListItem>
                        <ListItem>Your eligibility</ListItem>
                        <ListItem>Main features of the credit cards</ListItem>
                        <ListItem>Lender criteria</ListItem>
                    </UnorderedList>
                </Box>
                <Text variant="100">
                    Our eligibility checking service works with a wide range of
                    lenders and other brokers from across the market to find the
                    best offers for you. We then sort your offers based on their
                    features and what’s best for your profile. Although we may
                    be paid commission, this never influences how they’re
                    ranked.
                </Text>
            </Stack>
        </Modal>
    )
}

export default BestMatchModal
