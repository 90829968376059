import Box from '@totallymoney/ui/components/Box'
import React from 'react'

interface BannerStackProps {
    children: React.ReactNode
}

const BannerStack = ({ children }: BannerStackProps): React.ReactElement => {
    return <Box mb="-space20">{children}</Box>
}

export default BannerStack
