import { useQuery } from '@tanstack/react-query'

import { handleApiResponse } from './utils/handleApiResponse'

interface Options {
    customerId: string
    shouldFetch: boolean
}

type HasUsedNativeAppResponse = {
    customerId: string
    hasEverUsedNativeApp: boolean
}

export const useHasEverUsedNativeAppQuery = ({
    customerId,
    shouldFetch = true,
}: Options) => {
    return useQuery<HasUsedNativeAppResponse>(
        ['hasUsedNativeApp', customerId],
        () =>
            fetch(
                `${process.env.NEXT_PUBLIC_BASE_PATH}/api/customer-engagement/`,
                {
                    method: 'GET',
                    headers: {
                        'x-customerid': customerId,
                    },
                }
            ).then(handleApiResponse),
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
            enabled: shouldFetch,
        }
    )
}
