import { ComparatorKey } from '@utils/sortCardsBySortKey'

import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

export type CategoryComparatorKey =
    | 'introBtOffer'
    | 'introPurchaseOffer'
    | 'apr'
    | 'transferFee'
    | 'cashbackEarned'
    | 'combinedIntroOffers'
    | 'rewardsEarned'
    | 'maxCreditLimit'
    | 'internationalCharges'

export const categorySortKeyComparatorKeys: {
    [TCategory in CreditCardProductCategory]: {
        [Key in CategoryComparatorKey]?: ComparatorKey[]
    }
} = {
    BestCards: {
        introBtOffer: ['bt-offer', 'purchase-offer', 'apr', 'display-order'],
        introPurchaseOffer: [
            'purchase-offer',
            'bt-offer',
            'apr',
            'display-order',
        ],
        apr: ['apr', 'display-order'],
    },
    BalanceTransfer: {
        introBtOffer: ['bt-offer', 'apr', 'display-order'],
        transferFee: ['transfer-fee', 'bt-offer', 'apr', 'display-order'],
        apr: ['apr', 'display-order'],
    },
    Purchase: {
        introPurchaseOffer: ['purchase-offer', 'apr', 'display-order'],
        apr: ['apr', 'display-order'],
    },
    Cashback: {
        apr: ['apr', 'display-order'],
    },
    Combined: {
        introBtOffer: ['bt-offer', 'purchase-offer', 'apr', 'display-order'],
        introPurchaseOffer: [
            'purchase-offer',
            'bt-offer',
            'apr',
            'display-order',
        ],
        combinedIntroOffers: [
            'combined-introductory-offers',
            'apr',
            'display-order',
        ],
        apr: ['apr', 'display-order'],
    },
    Rewards: {
        apr: ['apr', 'display-order'],
    },
    PoorCredit: {
        apr: ['apr', 'bt-offer', 'purchase-offer', 'display-order'],
        maxCreditLimit: [
            'max-credit-limit',
            'apr',
            'bt-offer',
            'purchase-offer',
            'display-order',
        ],
    },
    Travel: {
        apr: [
            'apr',
            'international-charges',
            'purchase-offer',
            'bt-offer',
            'display-order',
        ],
        internationalCharges: [
            'international-charges',
            'apr',
            'purchase-offer',
            'bt-offer',
            'display-order',
        ],
    },
}

export type CategorySortKeyComparatorKeys = typeof categorySortKeyComparatorKeys

export const sorterDisplayNames: Record<CategoryComparatorKey, string> = {
    introBtOffer: 'Introductory Balance Transfer Offer',
    introPurchaseOffer: 'Introductory Purchase Offer',
    apr: 'APR',
    transferFee: 'Transfer Fee',
    cashbackEarned: 'Cashback Earned',
    combinedIntroOffers: 'Combined Introductory Offers',
    rewardsEarned: 'Rewards Earned',
    maxCreditLimit: 'Max Credit Limit',
    internationalCharges: 'International Charges',
} as const

export const defaultSortKeys: {
    [TCategory in CreditCardProductCategory]: keyof CategorySortKeyComparatorKeys[TCategory]
} = {
    BestCards: 'introBtOffer',
    BalanceTransfer: 'introBtOffer',
    Purchase: 'introPurchaseOffer',
    Cashback: 'apr',
    Combined: 'combinedIntroOffers',
    Rewards: 'apr',
    PoorCredit: 'apr',
    Travel: 'internationalCharges',
}
