import theme from '@totallymoney/ui/theme'
import styled from 'styled-components'

export const Wrapper = styled.div`
    grid-column: 1/13;
    background-color: white;
    border-radius: ${theme.radii.borderRadius20};
    margin-top: ${theme.space40};
    margin-bottom: ${theme.space20};
    padding: ${theme.space20};
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-column-gap: ${theme.space20};
    box-shadow: 0px 2px 8px rgba(8, 5, 28, 0.08);
`
