import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'
import LinkInline from '@totallymoney/ui/components/LinkInline'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'
import React from 'react'
import styled from 'styled-components'

import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

const GuidesContainer = styled.article`
    background-color: ${theme.productNeutral100};
`

const GuidesBox = styled(Box)`
    margin: 0 auto;
    max-width: 1200px;
`

const GuidesList = styled(Box)`
    list-style-type: disc;
    margin-left: ${theme.space20};

    li + li {
        margin-top: ${theme.space10};
    }
`

interface FrequentlyAskedQuestionsProps {
    category: CreditCardProductCategory
}

function FrequentlyAskedQuestions(props: FrequentlyAskedQuestionsProps) {
    switch (props.category) {
        case 'BalanceTransfer':
            return (
                <GuidesContainer>
                    <GuidesBox px={['space30', 'space80']} pb="space50">
                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    variant="40"
                                    id="what-is-a-balance-transfer-credit-card-"
                                >
                                    What is a balance transfer credit card?
                                </Heading>
                            </Box>
                            <Text variant="80">
                                A 0% balance transfer card can be an effective
                                way to cut the cost of existing debt &ndash; you
                                can transfer your existing credit card balance
                                and take advantage of a 0% interest rate for a
                                set period of time.
                            </Text>
                            <Text variant="80">
                                If you’re currently paying interest, you could
                                save money.
                            </Text>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    variant="40"
                                    id="how-much-could-i-save-"
                                >
                                    How much could I save?
                                </Heading>
                            </Box>
                            <Text variant="80">
                                Use our helpful Savings Calculator at the top of
                                this page to find out how much a balance
                                transfer could save you.
                            </Text>
                            <Text variant="80">
                                You can also move debts from multiple cards onto
                                a single balance transfer card. With some cards,
                                this can include overdraft debt, too &ndash;
                                potentially saving you hundreds, if not
                                thousands of pounds.
                            </Text>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading variant="40" id="what-will-it-cost-">
                                    What will it cost?
                                </Heading>
                            </Box>
                            <Text variant="80">
                                There is a fee to pay, typically 1&ndash;3%,
                                when you move your debt onto a balance transfer
                                card. In most cases, this is dwarfed by the
                                savings you could make.
                            </Text>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    variant="40"
                                    id="which-balance-transfer-card-is-right-for-me-"
                                >
                                    Which balance transfer card is right for me?
                                </Heading>
                            </Box>
                            <Text variant="80">
                                Consider the following when deciding on a
                                balance transfer card:
                            </Text>
                            <Box pl="space15">
                                <GuidesList as="ul">
                                    <li>
                                        <Text variant="80">
                                            The length of time it will take you
                                            to clear the debt against the size
                                            of the balance transfer fee. The
                                            shorter the 0% deal, the lower the
                                            fee tends to be.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            Your credit history. Many balance
                                            transfer cards require you to have a
                                            good credit score. Use our free
                                            eligibility checker to find out your
                                            chances of acceptance, without
                                            harming your credit rating.
                                        </Text>
                                    </li>
                                </GuidesList>
                            </Box>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    variant="40"
                                    id="how-do-i-transfer-a-balance-"
                                >
                                    How do I transfer a balance?
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    Making a balance transfer is incredibly
                                    easy. Simply give the balance transfer card
                                    lender the details of the debts you want to
                                    transfer. They will pay off those debts and
                                    create a new balance on your 0% card.
                                </Text>
                                <Text variant="80">
                                    You usually need to do this within
                                    60&ndash;90 days of taking out the new card.
                                    Be sure to close your old cards, as having
                                    multiple accounts open can affect your
                                    credit rating.
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    variant="40"
                                    id="the-golden-rules-of-balance-transfer"
                                >
                                    The golden rules of balance transfer
                                </Heading>
                            </Box>
                            <Text variant="80">
                                Here’s how to make sure you manage your balance
                                transfer card wisely:
                            </Text>
                            <Box pl="space15" pb="space05">
                                <GuidesList as="ul">
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Never miss a payment:
                                            </strong>{' '}
                                            A balance transfer card can give you
                                            a lot more control over the amount
                                            you repay each month. If you miss a
                                            payment, or are late making a
                                            payment, you may incur a financial
                                            penalty. Avoid this by setting up a
                                            direct debit to repay at least the
                                            minimum every month.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Never outstay your welcome:
                                            </strong>{' '}
                                            The 0% interest deal is only for a
                                            limited time. When it runs out any
                                            remaining balance will start earning
                                            interest at an often much higher
                                            rate. Clear your debt before the end
                                            of the deal or switch the debt to a
                                            new balance transfer card.{' '}
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Never spend on a balance
                                                transfer card:
                                            </strong>{' '}
                                            Avoid spending or withdrawing cash
                                            on your balance transfer card. The
                                            0% deal is only offered on the
                                            balance moved from another credit
                                            source. Anything additional to this
                                            will be charged interest and the
                                            rate on balance transfer cards tends
                                            to be quite high.{' '}
                                        </Text>
                                    </li>
                                </GuidesList>
                            </Box>
                            <Text variant="80">
                                If you do need a card that you can transfer a
                                balance to and spend on, consider a combined
                                balance transfer and 0% purchase card which
                                offer interest free deals on both &ndash;{' '}
                                <LinkInline href="https://www.totallymoney.com/credit-cards/0-percent/">
                                    find a combined card
                                </LinkInline>
                                .
                            </Text>
                        </Box>
                    </GuidesBox>
                </GuidesContainer>
            )
        case 'Cashback':
            return (
                <GuidesContainer>
                    <GuidesBox px={['space30', 'space80']} pb="space50">
                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    variant="40"
                                    id="understanding-cashback-credit-cards"
                                >
                                    Understanding cashback credit cards
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    Earn while you spend with a cashback credit
                                    card.{' '}
                                </Text>
                                <Text variant="80">
                                    Cashback is an incentive operated by credit
                                    card companies where a percentage of the
                                    amount spent is paid back to the card
                                    holder.{' '}
                                </Text>
                                <Text variant="80">
                                    If you use a cashback credit card for all of
                                    your spending, you could earn hundreds of
                                    pounds every year.
                                </Text>
                                <Text variant="80">
                                    However, the credit card companies aren’t
                                    giving you money for nothing. The incentive
                                    was created in hope that you’ll spend on
                                    your cashback card and not clear your
                                    balance in full.{' '}
                                </Text>
                                <Text variant="80">
                                    If you don’t clear your balance, they can
                                    earn far more in interest than you will ever
                                    earn in cashback. So, only use a cashback
                                    credit card if you know you can pay off the
                                    balance in full each month.
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    variant="40"
                                    id="should-i-get-a-cashback-credit-card-"
                                >
                                    Should I get a cashback credit card?
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    If you can repay your credit card balance in
                                    full each month, a cashback credit card is
                                    for you. Use it sensibly and you could reap
                                    the rewards.
                                </Text>
                                <Text variant="80">
                                    But if you’re already paying interest on
                                    existing credit card debt, a{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/balance-transfer/">
                                        balance transfer card
                                    </LinkInline>{' '}
                                    is your best bet. Similarly, if you’re
                                    planning a larger purchase and will need an
                                    extended period to clear your balance in
                                    full, opt for a{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/purchase/">
                                        0% purchases
                                    </LinkInline>{' '}
                                    card.{' '}
                                </Text>
                                <Text variant="80">
                                    Both of these options will save you more
                                    money than you could ever earn with a
                                    cashback credit card.{' '}
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    variant="40"
                                    id="how-to-get-the-most-from-your-cashback-credit-card"
                                >
                                    How to get the most from your cashback
                                    credit card
                                </Heading>
                            </Box>
                            <Text variant="80">
                                Used sensibly, a cashback credit card can save
                                you money, but a few slip-ups could wipe out
                                your winnings in seconds. Follow these tips to
                                make your cashback card work for you:
                            </Text>
                            <Box pl="space15">
                                <GuidesList as="ul">
                                    <li>
                                        <Text variant="80">
                                            <strong>Know your card:</strong> As
                                            different cards offer different
                                            cashback rates, It’s important to
                                            get the card that best matches your
                                            shopping habits. Some offer a high
                                            introductory offer that drops after
                                            a few months, while others give you
                                            a higher rate on certain forms of
                                            spending.{' '}
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>Spend sensibly:</strong> Pay
                                            for as much of your day-to-day
                                            spending on your card as possible.
                                            If you can, pay by credit card
                                            rather than cash, cheque or debit
                                            card &ndash; providing you can
                                            afford to repay your balance in full
                                            at the end of the month.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Set up a direct debit:
                                            </strong>{' '}
                                            Ensure you never miss a payment and
                                            end up paying interest by setting up
                                            a monthly direct debit to repay your
                                            balance in full.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>Avoid penalties:</strong>{' '}
                                            Not only are cash withdrawals exempt
                                            from cashback, but you’ll also start
                                            paying interest on the withdrawal
                                            from the moment the money is in your
                                            hands. Unless it’s an emergency,
                                            avoid using your cashback card in an
                                            ATM.
                                        </Text>
                                    </li>
                                </GuidesList>
                            </Box>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading variant="40" id="cashback-vs-rewards">
                                    Cashback vs rewards
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    Cashback is a type of reward&hellip; in the
                                    form of cold, hard cash. But that doesn’t
                                    mean it’s always the best option.
                                </Text>
                                <Text variant="80">
                                    If you fly a lot, a credit card linked to an
                                    airline reward program could end up being
                                    more useful than a cashback credit card.
                                    Similarly, if you’re loyal to a particular
                                    supermarket then their store card could be
                                    more beneficial.{' '}
                                </Text>
                                <Text variant="80">
                                    Overall, because you need to spend a lot
                                    before rewards have any real value, cashback
                                    cards are often the better choice for most
                                    people.
                                </Text>
                            </Stack>
                        </Box>
                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    variant="40"
                                    id="will-i-be-accepted-for-a-cashback-card-"
                                >
                                    Will I be accepted for a cashback card?
                                </Heading>
                            </Box>
                            <Text variant="80">
                                While the best cashback deals tend to be
                                reserved for those with the best credit ratings,
                                there are still options available if you have a
                                less than perfect score.
                            </Text>
                            <Text variant="80">
                                Just avoid applying for multiple cards at once
                                &ndash; every unsuccessful application leaves a
                                mark on your credit rating. Our free eligibility
                                checker will show you the credit cards that
                                could accept you.
                            </Text>
                        </Box>
                    </GuidesBox>
                </GuidesContainer>
            )
        case 'Combined':
            return (
                <GuidesContainer>
                    <GuidesBox px={['space30', 'space80']} pb="space50">
                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    variant="40"
                                    id="understanding-0-balance-transfer-and-purchase-cards"
                                >
                                    Understanding 0% balance transfer and
                                    purchase cards
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    A combined balance transfer and purchase
                                    card is effectively two cards in one.{' '}
                                </Text>
                                <Text variant="80">
                                    Providing the best of both, it offers a
                                    combination of an interest-free period on
                                    balance transfers and a 0% interest period
                                    on new spending.{' '}
                                </Text>
                                <Text variant="80">
                                    This means that there’s no excuse for paying
                                    interest on your existing credit card debt
                                    or new purchases. Plus, you’ll only need to
                                    apply for one credit card.
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    variant="40"
                                    id="is-this-card-right-for-me-"
                                >
                                    Is this card right for me?
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    A combined balance transfer and purchase
                                    card can be useful in certain situations,
                                    but there may be a better card out there.
                                    Follow these rules to work out if it’s the
                                    right card for you.
                                </Text>
                                <Text variant="80">
                                    If you want one card for all of your credit
                                    needs, a combined balance transfer and
                                    purchase card is an effective tool. You
                                    won’t have to monitor several credit card
                                    accounts or make multiple credit card
                                    applications.
                                </Text>
                                <Text variant="80">
                                    However, if you’re looking for the cheapest
                                    way to clear your debt this isn’t it. You
                                    will get a much longer 0% period if you opt
                                    for a balance transfer credit card. While
                                    you may not be able to use a balance
                                    transfer card to spend, they do tend to
                                    offer a cheaper transfer fee too &ndash;{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/balance-transfer/">
                                        find a balance transfer card
                                    </LinkInline>
                                    .
                                </Text>
                                <Text variant="80">
                                    If you don’t have any existing credit card
                                    debt and are looking for a cheap way to
                                    spend, try a 0% purchase card instead. The
                                    interest-free period is likely to be longer
                                    and you’re likely to be better off &ndash;{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/purchase/">
                                        check your eligibility for a 0% purchase
                                        card
                                    </LinkInline>
                                    .
                                </Text>
                                <Text variant="80">
                                    Similarly, if you clear your balance in full
                                    each month, a{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/rewards/">
                                        rewards
                                    </LinkInline>{' '}
                                    or{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/cashback/">
                                        cashback
                                    </LinkInline>{' '}
                                    card will better suit your spending habits.
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    variant="40"
                                    id="how-to-make-a-combined-card-work-for-you"
                                >
                                    How to make a combined card work for you
                                </Heading>
                            </Box>
                            <Text variant="80">
                                Use a combined balance transfer and purchase
                                card carefully and you can borrow money without
                                paying a penny. Here’s how:
                            </Text>
                            <Box pl="space15">
                                <GuidesList as="ul">
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Never miss a payment:
                                            </strong>{' '}
                                            Set up a direct debit to cover at
                                            least the minimum payment on your
                                            card. Missing even one payment will
                                            have a negative effect on your
                                            credit rating and your provider
                                            could even stop your 0% interest
                                            deal.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Cancel your old cards:
                                            </strong>{' '}
                                            Improve your credit rating in the
                                            process by cancelling your old
                                            cards. Lenders look at how much
                                            credit you have available to you,
                                            even if you’ve since transferred
                                            your balance.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Clear your debt in time:
                                            </strong>{' '}
                                            Don’t leave debt on a combined
                                            credit card after the 0% period
                                            ends. The interest charges will
                                            cancel out the benefit of using the
                                            card in the first place. If you
                                            can’t clear your balance in time, it
                                            can pay to move your debt to another
                                            0% deal.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Remember the deal end date:
                                            </strong>{' '}
                                            Avoid paying interest by making a
                                            note or setting a reminder of when
                                            the 0% period ends.
                                        </Text>
                                    </li>
                                </GuidesList>
                            </Box>
                        </Box>
                    </GuidesBox>
                </GuidesContainer>
            )
        case 'BestCards':
            return (
                <GuidesContainer>
                    <GuidesBox px={['space30', 'space80']} pb="space50">
                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="understanding-credit-cards"
                                >
                                    Understanding credit cards
                                </Heading>
                            </Box>
                            <Box mb={'space30'}>
                                <Text variant="80">
                                    When you pay for something with a credit
                                    card, you are borrowing money. Most credit
                                    cards come with an interest-free period of
                                    up to 56 days, so if you pay off your bill
                                    in full each month, a credit card can be a
                                    useful addition to your wallet.
                                </Text>
                            </Box>
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="8-reasons-why-you-need-a-credit-card"
                                >
                                    8 reasons why you may need a credit card
                                </Heading>
                            </Box>
                            <Box pl="space15">
                                <GuidesList as="ul">
                                    <li>
                                        <Text variant="80">
                                            <strong>Peace of mind:</strong>{' '}
                                            Credit cards come with free
                                            additional consumer protection,
                                            known as Section 75. If a purchase
                                            isn’t as described or simply doesn’t
                                            turn up, you can turn to your credit
                                            card provider for financial
                                            compensation as well as the
                                            retailer.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>Low cost borrowing:</strong>{' '}
                                            Using the right credit card is one
                                            of the cheapest ways to borrow.
                                            Whether you want to clear existing
                                            debt or build up new debt, you may
                                            have multiple options. Find the
                                            right card for you with our free
                                            comparison service.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Earn rewards or cashback:
                                            </strong>{' '}
                                            Many credit cards reward you for
                                            using them, either with money or
                                            points. Why shop with cash or a
                                            debit card when you could be earning
                                            rewards or cashback?
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Savvy travellers use plastic:
                                            </strong>{' '}
                                            Not only do you get extra consumer
                                            protection with Section 75 when
                                            travelling, a credit card can also
                                            save you money abroad. When using
                                            the right credit card abroad you can
                                            get a better exchange rate when
                                            compared to cash and without paying
                                            any commission.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Build a good credit report:
                                            </strong>{' '}
                                            A good credit score will help you
                                            get the best financial rates on
                                            mortgages, cars and more. A bad one
                                            will stop you getting pretty much
                                            anything you can’t pay up front for.
                                            Start building yours by getting a
                                            credit card and using it sensibly.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>Free insurance:</strong>{' '}
                                            Some credit cards come with free
                                            purchase protection for a short
                                            period after you buy. If an item you
                                            bought is lost or stolen within a
                                            set time &ndash; usually 90 days
                                            &ndash; you can get your money back
                                            subject to satisfying terms and
                                            conditions.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>Protect your money:</strong>{' '}
                                            If you lose your wallet full of
                                            cash, the chances are you will never
                                            see that money again. If you lose
                                            your credit card, provided you were
                                            careful, you won’t be liable for
                                            money spent on it after you lost it.
                                            Just make sure you report the loss
                                            swiftly and are always careful with
                                            your pin number. If the credit card
                                            company can prove you were negligent
                                            they won’t pay up.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                In some places, it’s the only
                                                way to pay:
                                            </strong>{' '}
                                            Some places &ndash; such as hotels
                                            or car rental outlets &ndash; will
                                            still insist on a credit card when
                                            taking payment. This is because with
                                            a credit card they get greater
                                            assurance that they can recoup any
                                            additional costs you run up.
                                        </Text>
                                    </li>
                                </GuidesList>
                            </Box>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="the-different-types-of-credit-card"
                                >
                                    The different types of credit card
                                </Heading>
                            </Box>
                            <Text weight="bold" id="balance-transfer">
                                Balance transfer
                            </Text>
                            <Stack space="1" mb="space10">
                                <Text variant="80">
                                    If you want to cut the interest on your
                                    existing debts, a balance transfer credit
                                    card is for you. With a balance transfer
                                    credit card you can move balances you’ve
                                    accumulated on other credit cards and enjoy
                                    0% interest for a set period of time.
                                </Text>
                                <Text variant="80">
                                    It’s not completely free, there is a balance
                                    transfer fee to pay when you initially move
                                    money. Usually the longer the deal is, the
                                    higher the balance transfer fee will be.
                                    Also be aware that you will pay interest on
                                    any money you spend on a balance transfer
                                    card. It’s best to keep things simple and
                                    have a separate card for spending. Or use a
                                    balance transfer and purchases card, see
                                    below.{' '}
                                </Text>
                                <Text variant="80">
                                    When choosing a balance transfer card work
                                    out how long you need the interest free
                                    period to be and then consider the balance
                                    transfer fee. Our credit comparison service
                                    can help you{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/balance-transfer/">
                                        find the right balance transfer card
                                    </LinkInline>{' '}
                                    that you’re most likely to be accepted for.
                                    Plus, you can calculate which should deliver
                                    you the biggest saving.{' '}
                                </Text>
                            </Stack>
                            <Text weight="bold" id="purchase">
                                Purchase
                            </Text>
                            <Stack space="1" mb="space10">
                                <Text variant="80">
                                    This is the simplest type of credit card.
                                    With a purchase card you’ll get a 0%
                                    interest period for a set amount of time,
                                    allowing you to spend without accruing any
                                    interest, even if you don’t pay your balance
                                    back in full each month.{' '}
                                </Text>
                                <Text variant="80">
                                    If you want to spend money on larger
                                    purchases and not pay it back straight away,
                                    this is the card for you. Just clear the
                                    balance before the 0% period runs out, as
                                    the standard interest rate on these cards
                                    can be high &ndash;{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/purchase/">
                                        find a 0% purchase card
                                    </LinkInline>
                                </Text>
                            </Stack>
                            <Text
                                weight="bold"
                                id="balance-transfer-and-purchase"
                            >
                                Balance transfer and purchase
                            </Text>
                            <Stack space="1" mb="space10">
                                <Text variant="80">
                                    If you have built up some credit card debt
                                    and still want to spread the cost of big
                                    purchases, a{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/0-percent/">
                                        combined balance transfer and purchase
                                        credit card
                                    </LinkInline>{' '}
                                    could be the answer.
                                </Text>
                                <Text variant="80">
                                    For a set period of time, these cards offer
                                    a 0% interest period on both debt
                                    transferred from other credit cards and new
                                    purchases. The drawback is that the 0%
                                    periods are shorter than if you just went
                                    for a balance transfer card, or just a
                                    purchase card.
                                </Text>
                                <Text variant="80">
                                    So, if you have a large amount of debt that
                                    you can’t clear within a 0% balance transfer
                                    and purchase card offer, consider getting a
                                    pure balance transfer card and a separate
                                    purchase card.
                                </Text>
                                <Text weight="bold" id="cashback">
                                    Cashback
                                </Text>
                                <Text variant="80">
                                    If you clear your credit card balance every
                                    month, and have no plans to build up any
                                    debt, a cashback credit card is a good
                                    option. These cards reward your spending
                                    habits by giving you a percentage of your
                                    spending back in the form of cash.
                                </Text>
                                <Text variant="80">
                                    Just be aware that these cards often have
                                    high interest rates, making them only worth
                                    using if you’re going to pay your balance
                                    back in full each month.{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/cashback/">
                                        See cashback cards.
                                    </LinkInline>
                                </Text>
                            </Stack>
                            <Text weight="bold" id="rewards">
                                Rewards
                            </Text>
                            <Stack space="1" mb="space10">
                                <Text variant="80">
                                    An alternative option for people who clear
                                    their balance each month is a rewards credit
                                    card. Rather than paying you back with cash,
                                    these cards offer various forms of points in
                                    return from using them. These can range from
                                    airmiles to supermarket points.{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/rewards/">
                                        Compare rewards credit cards.
                                    </LinkInline>
                                </Text>
                                <Text
                                    weight="bold"
                                    id="poor-credit-credit-cards"
                                >
                                    Poor credit credit cards
                                </Text>
                                <Text variant="80">
                                    If you have poor credit, a poor credit
                                    credit card can help you build your credit
                                    rating over time. While they have high
                                    interest rates, if you pay your balance in
                                    full each month, they can be an effective
                                    way of showing lenders you can be trusted.{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/credit-cards-bad-credit/">
                                        View credit cards for poor credit.
                                    </LinkInline>
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="4-tips-to-make-a-credit-card-work-for-you"
                                >
                                    4 Tips to make a credit card work for you
                                </Heading>
                            </Box>
                            <Box pl="space15">
                                <GuidesList as="ul">
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Set up a direct debit:
                                            </strong>{' '}
                                            Never miss a payment. If you do,
                                            you’ll face penalty fees, extra
                                            interest and the possibility of
                                            losing any 0% deal you may have.
                                            Combat this by setting up a direct
                                            debit for at least the minimum
                                            payment to ensure you always pay on
                                            time.{' '}
                                        </Text>
                                    </li>

                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Minimise your applications:
                                            </strong>{' '}
                                            Every unsuccessful credit
                                            application will leave a mark on
                                            your credit report. In the eyes of
                                            lenders, having multiple
                                            applications on your file makes you
                                            appear desperate for credit.{' '}
                                        </Text>
                                        <Text variant="80">
                                            {' '}
                                            Our{' '}
                                            <LinkInline href="https://www.totallymoney.com/credit-cards/find-eligibility/">
                                                free eligibility checker
                                            </LinkInline>{' '}
                                            can help you reduce the number of
                                            credit card applications you make.
                                            Enter a few details and, without
                                            leaving a shadow on your credit
                                            record, it will tell you which cards
                                            are most likely to accept your
                                            application.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>Cancel old cards:</strong>{' '}
                                            If you have any old credit cards
                                            that you no longer use, cancel them.
                                            When a credit card provider is
                                            deciding whether to lend to you,
                                            they look at how much credit you
                                            already have access to. This
                                            includes existing credit card limits
                                            and overdrafts. So, eliminate any
                                            credit you don’t use.{' '}
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>Know your deal:</strong> Be
                                            clear about what your credit card’s
                                            interest rate is, the fees it
                                            charges and when any introductory
                                            deal you may have ends. If you don’t
                                            know these details, you risk
                                            accidentally paying interest or
                                            fees.
                                        </Text>
                                    </li>
                                </GuidesList>
                            </Box>
                        </Box>
                    </GuidesBox>
                </GuidesContainer>
            )
        case 'Purchase':
            return (
                <GuidesContainer>
                    <GuidesBox px={['space30', 'space80']} pb="space50">
                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="what-is-a-0-purchase-card-"
                                >
                                    What is a 0% purchase card?
                                </Heading>
                            </Box>
                            <Text variant="80">
                                If you’re planning a major purchase, a 0%
                                purchase credit card is the cheapest way to
                                borrow. These cards offer an interest-free
                                period during which you can spread the cost of
                                larger purchases.{' '}
                            </Text>
                            <Text variant="80">
                                Providing you can pay the entire balance back
                                before the interest-free period ends, a 0%
                                purchase card can be an unrivalled addition to
                                your wallet.
                            </Text>
                            <Text variant="80">
                                Aside from taking advantage of the interest rate
                                offers available, 0% purchase cards offer free
                                consumer protection: Under Section 75, the
                                credit card company is jointly liable with the
                                supplier for any failed goods and services
                                between £100 and £30,000 &ndash; making a 0%
                                purchase card a useful tool when online
                                shopping.{' '}
                            </Text>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="is-a-0-purchase-card-right-for-me-"
                                >
                                    Is a 0% purchase card right for me?
                                </Heading>
                            </Box>
                            <Text variant="80">
                                For larger purchases, such as annual season
                                tickets, 0% purchase cards make an ideal choice.
                                However, existing debts or uncleared balances
                                can quickly wipe out the benefits of these
                                cards. Read this to find out if a 0% purchase
                                card is right for you:
                            </Text>
                            <Box pl="space15">
                                <GuidesList as="ul">
                                    <li>
                                        <Text variant="80">
                                            Can you afford to clear the debt
                                            quickly? If you know you can repay
                                            for larger purchases within the
                                            card’s interest free period, a 0%
                                            purchase card is ideal for you.{' '}
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            Do you have a good credit score?
                                            Each lender has their own criteria,
                                            but if you are starting to build up
                                            your credit history you could
                                            struggle to get a 0% purchase card.
                                            Our credit comparison tool will find
                                            out if there are any deals you may
                                            be accepted for, without harming
                                            your credit rating.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            Do you have existing credit card
                                            debts? If you’re currently paying
                                            interest on an existing card, it’s
                                            most likely that your best option is
                                            to transfer your debt to a 0%
                                            balance transfer card. Check out our
                                            best balance transfer cards here.
                                        </Text>
                                    </li>
                                </GuidesList>
                            </Box>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="golden-rules-for-0-purchase-cards"
                                >
                                    Golden rules for 0% purchase cards
                                </Heading>
                            </Box>
                            <Box pl="space15" pb="space05">
                                <GuidesList as="ul">
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Check before you transfer a
                                                balance:
                                            </strong>{' '}
                                            If you transfer an existing credit
                                            card balance to a 0% purchase card,
                                            you may find yourself being charged
                                            interest. Check your deal carefully
                                            to see if the 0% is for balance
                                            transfers as well as purchases. It
                                            pays to read the full terms and
                                            conditions.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Ensure your borrowing stays
                                                free:
                                            </strong>{' '}
                                            If you miss a payment, or are late
                                            making a payment, you may incur a
                                            financial penalty. Combat this by
                                            setting up a direct debit to cover
                                            at least the minimum repayment each
                                            month.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Understand your minimum
                                                payments:
                                            </strong>{' '}
                                            The amount you repay each month on a
                                            0% purchase card is not decided
                                            according to your interest rate.
                                            Instead, you have a lot more control
                                            over your payments. You can opt to
                                            either pay the minimum or you can
                                            ask to repay a set amount each
                                            month. If there is a chance that the
                                            minimum payment will be higher than
                                            your current credit card, make sure
                                            you can afford it.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            <strong>
                                                Never outstay your welcome:
                                            </strong>{' '}
                                            Be aware that the 0% interest on
                                            your card is only for a limited
                                            time. It’s worth making a note of
                                            this date in your phone or diary.
                                            When it runs out, any remaining
                                            balance will start incurring
                                            interest at a usually uncompetitive
                                            rate. Clearing your balance before
                                            the 0% period runs out is highly
                                            recommended.{' '}
                                        </Text>
                                    </li>
                                </GuidesList>
                            </Box>
                            <Text variant="80">
                                In the event that you can’t clear your balance
                                in time, switch the debt to a new balance
                                transfer card. Just ensure you allow enough time
                                for the application to be processed. Start
                                shopping around for a new card at least a month
                                before your 0% interest offer runs out &ndash;
                                our free credit card comparison tool can help.
                            </Text>
                        </Box>
                    </GuidesBox>
                </GuidesContainer>
            )
        case 'Rewards':
            return (
                <GuidesContainer>
                    <GuidesBox px={['space30', 'space80']} pb="space50">
                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="what-is-a-rewards-credit-card-"
                                >
                                    What is a rewards credit card?
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    A rewards credit card helps you earn points
                                    which can be exchanged for travel,
                                    merchandise or discounts.{' '}
                                </Text>
                                <Text variant="80">
                                    Every time you purchase something with a
                                    rewards card the credit card company will
                                    give you points that can be used to buy a
                                    whole host of things depending on that
                                    card’s individual rewards scheme.{' '}
                                </Text>
                                <Text variant="80">
                                    Credit card companies aren’t just being
                                    generous. They’re hoping that you use their
                                    card because of the rewards and not clear
                                    your balance in full. They can earn far more
                                    in interest than you will receive in
                                    rewards.
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="should-i-get-a-rewards-credit-card-"
                                >
                                    Should I get a rewards credit card?
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    Yes, if you can repay your credit card
                                    balance in full each month.{' '}
                                </Text>
                                <Text variant="80">
                                    If you’re planning some larger purchases and
                                    won’t be able to clear your balance in full,
                                    opt for{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/purchase/">
                                        0% purchase card
                                    </LinkInline>{' '}
                                    instead.{' '}
                                </Text>
                                <Text variant="80">
                                    Similarly, if you’re paying interest on an
                                    existing credit card, try a{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/balance-transfer/">
                                        0% balance transfer card
                                    </LinkInline>{' '}
                                    &ndash; this will save you far more money
                                    than a rewards credit card.
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="how-to-get-the-most-from-your-rewards-card-"
                                >
                                    How to get the most from your rewards card:
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    Used sensibly, a rewards credit card can
                                    earn you money, but a few mistakes could
                                    wipe out your winnings in seconds. Follow
                                    these tips to make your rewards credit card
                                    work for you:
                                </Text>
                                <Box pl="space15">
                                    <GuidesList as="ul">
                                        <li>
                                            <Text variant="80">
                                                <strong>Spend sensibly:</strong>{' '}
                                                To unlock the best results from
                                                your credit card, think of of it
                                                like a monthly debit card. Spend
                                                no more than you usually would
                                                and remain in control to benefit
                                                in full from the rewards on
                                                offer.
                                            </Text>
                                        </li>
                                        <li>
                                            <Text variant="80">
                                                <strong>
                                                    Set up a direct debit:
                                                </strong>{' '}
                                                Ensure you never miss a payment
                                                and end up paying interest by
                                                setting up a monthly direct
                                                debit to repay your balance in
                                                full.
                                            </Text>
                                        </li>
                                    </GuidesList>
                                </Box>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="which-rewards-card-is-right-for-me-"
                                >
                                    Which rewards card is right for me?
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    The best rewards card for you depends on
                                    your lifestyle and spending habits.
                                </Text>
                                <Text variant="80">
                                    Some of these credit cards have tight
                                    acceptance criteria &ndash; use our free and
                                    impartial{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/find-eligibility/rewards/">
                                        credit comparison tool
                                    </LinkInline>{' '}
                                    to see your chances of acceptance.
                                </Text>
                                <Text variant="80">
                                    <strong>Cash is king:</strong> For most
                                    people, cashback cards make the best choice.
                                    Simply use your card and your credit card
                                    provider will give you a small percentage of
                                    your spending back in cash &ndash;{' '}
                                    <LinkInline href="https://www.totallymoney.com/credit-cards/cashback/">
                                        find a cashback credit card
                                    </LinkInline>
                                    .{' '}
                                </Text>
                                <Text variant="80">
                                    <strong>Loyalty pays:</strong> If you’re
                                    loyal to certain stores, in particular
                                    supermarkets, then rewards tied to these
                                    shops might be the best option for you.
                                    Generally, these cards return more reward
                                    value per pound on spending.{' '}
                                </Text>
                                <Text variant="80">
                                    <strong>The price of freedom:</strong> The
                                    most common rewards cards let you exchange
                                    your points for treats, such as restaurant
                                    bills or cinema tickets, in a wide array of
                                    places. To find the best card, make sure you
                                    look at not only what you’ll be able to buy,
                                    but how long it will be before you can buy
                                    it. In the world of rewards, multiple
                                    spending options doesn’t equal the biggest
                                    return.
                                </Text>
                                <Text variant="80">
                                    <strong>Fly high with airmiles:</strong>{' '}
                                    Frequent fliers can earn more with an
                                    airmiles credit card than cashback or other
                                    rewards. Just be aware that most flight
                                    reward schemes only allow you to book
                                    certain flights (usually those that they
                                    struggle to sell). Airmiles only beat other
                                    cards if you’re prepared to spend some time
                                    researching how best to spend them.
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="will-i-be-accepted-for-a-rewards-card-"
                                >
                                    Will I be accepted for a rewards card?
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    Used correctly, a rewards card can offer a
                                    lot of value, but the acceptance criteria
                                    for the best rewards cards can be pretty
                                    tight.
                                </Text>
                                <Text variant="80">
                                    Each lender has their own acceptance
                                    criteria, so it’s hard to know where to
                                    apply. Enter a few details and our
                                    personalised credit comparison tool will
                                    show you the rewards cards you’re most
                                    likely to be accepted for &ndash; all
                                    without harming your credit rating.
                                </Text>
                            </Stack>
                        </Box>
                    </GuidesBox>
                </GuidesContainer>
            )
        case 'PoorCredit':
            return (
                <GuidesContainer>
                    <GuidesBox px={['space30', 'space80']} pb="space50">
                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="understanding-credit-cards-for-poor-credit"
                                >
                                    Understanding credit cards for poor credit
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    Poor credit credit cards, also called bad
                                    credit credit cards, are designed to help
                                    you if you have a poor, or non-existent,
                                    credit record. These cards accept people who
                                    would be rejected for a mainstream credit
                                    card.
                                </Text>
                                <Text variant="80">
                                    They are an excellent way of building up a
                                    good credit record. If you get one, use it
                                    sensibly and pay your balance off in full
                                    each month.
                                </Text>
                                <Text variant="80">
                                    However, they carry very high interest rates
                                    &ndash; typically 29&ndash;35%APR &ndash; so
                                    NEVER spend more than you can afford to pay
                                    back.
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="stop-the-cycle-of-credit-card-rejection"
                                >
                                    Stop the cycle of credit card rejection
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    If you’ve applied and been rejected for a
                                    credit card, read this before applying
                                    again.
                                </Text>
                                <Text variant="80">
                                    Being rejected is not only a waste of time,
                                    it can damage your credit rating, making it
                                    even harder to obtain credit and create a
                                    vicious circle of rejection.
                                </Text>
                                <Text variant="80">
                                    So, stop and assess why you’ve been turned
                                    down and then find the card most likely to
                                    accept you. Every card company has their own
                                    criteria for lending, so even if one has
                                    rejected you, it doesn’t mean that they all
                                    will.
                                </Text>
                                <Text variant="80">
                                    Before you apply again, find out which cards
                                    are most likely to accept you. Our
                                    eligibility check will tell you which credit
                                    cards you are most likely to be accepted
                                    for, without casting a shadow on your credit
                                    record &ndash; using it will not affect your
                                    credit rating.
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="how-does-the-eligibility-check-work-"
                                >
                                    How does the eligibility check work?
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    The eligibility check can help you decide on
                                    which card to apply for. Enter a few details
                                    and we’ll show you the cards you’re more
                                    likely to be approved for &ndash; and those
                                    that might reject you.
                                </Text>
                                <Text variant="80">
                                    Acceptance isn’t guaranteed, but we use a
                                    soft search &ndash; you’ll see it on your
                                    file, but lenders don’t, so there’s no
                                    impact on your credit rating &ndash; to show
                                    your chance of acceptance for each card.
                                </Text>
                                <Text variant="80">
                                    Don’t assume you’ll only get a &lsquo;bad
                                    credit’ credit card, you may be pleasantly
                                    surprised. But whatever result you get, use
                                    your new credit card to improve your credit
                                    rating.
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="what-is-a-poor-credit-credit-card-"
                                >
                                    What is a poor credit credit card?
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    Bad credit credit cards are designed for
                                    people who have had credit problems in the
                                    past or have little or no history of
                                    borrowing. If you fall into either category
                                    you represent an above-average risk to the
                                    lender, so you pay for that risk with higher
                                    interest rates and fewer special offers.
                                </Text>
                                <Text variant="80">
                                    As these cards are expensive they’re not
                                    great for new borrowing. But, if used
                                    sensibly &nsdash; that means paying the
                                    balance off in full each month &ndash; they
                                    can help you build up a good credit score.
                                </Text>
                                <Text variant="80">
                                    You’re likely to fall into the poor credit
                                    category if you have:
                                </Text>
                                <Box pl="space15">
                                    <GuidesList as="ul">
                                        <li>
                                            <Text variant="80">
                                                A limited or non-existent credit
                                                history
                                            </Text>
                                        </li>
                                        <li>
                                            <Text variant="80">
                                                Past issues such as repeated
                                                missed payments
                                            </Text>
                                        </li>
                                        <li>
                                            <Text variant="80">
                                                Prior County Court Judgements
                                            </Text>
                                        </li>
                                        <li>
                                            <Text variant="80">
                                                Been declined by several other
                                                credit card providers
                                            </Text>
                                        </li>
                                    </GuidesList>
                                </Box>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="what-are-bad-credit-credit-cards-good-for-"
                                >
                                    What are bad credit credit cards good for?
                                </Heading>
                            </Box>
                            <Box pl="space15">
                                <GuidesList as="ul">
                                    <li>
                                        <Text variant="80">
                                            They’re a great way to build or
                                            rebuild your credit rating.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            In some circumstances they can be a
                                            cheaper alternative to other forms
                                            of borrowing, such as payday loans
                                            or unauthorised overdrafts.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            Some poor credit cards feature 0%
                                            introductory rates and can be used
                                            carefully for low-cost, short-term
                                            borrowing.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            All bad credit credit cards come
                                            with useful free Section 75 purchase
                                            protection benefits.
                                        </Text>
                                    </li>
                                </GuidesList>
                            </Box>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="how-to-improve-your-credit-rating"
                                >
                                    How to improve your credit rating
                                </Heading>
                            </Box>
                            <Text variant="80">
                                Here’s how you can use a credit card to improve
                                your credit rating and, in time, gain access to
                                better rates and products:
                            </Text>
                            <Box pl="space15">
                                <GuidesList as="ul">
                                    <li>
                                        <Text variant="80">
                                            Use the card. An unused card sitting
                                            in a drawer won’t help your credit
                                            score, you need to flex the plastic,
                                            even if it is only to spend a few
                                            pounds each month.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            Stay within your credit limit.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            Repay your balance in full every
                                            month &ndash; set up a direct debit
                                            to ensure you don’t miss a payment.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            Never miss a monthly payment.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text variant="80">
                                            Never take on debt you can’t afford
                                            to pay off in full &ndash; bad
                                            credit credit cards tend to have
                                            high APRs and expensive interest
                                            costs could lead to you missing a
                                            repayment.
                                        </Text>
                                    </li>
                                </GuidesList>
                            </Box>
                        </Box>
                    </GuidesBox>
                </GuidesContainer>
            )
        case 'Travel':
            return (
                <GuidesContainer>
                    <GuidesBox px={['space30', 'space80']} pb="space50">
                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="what-is-a-travel-credit-card"
                                >
                                    What is a travel credit card?
                                </Heading>
                            </Box>
                            <Text variant="80">
                                Travel credit cards are a smart way to spend
                                money abroad and avoid the usual fees you would
                                be charged for using a regular credit card.
                                Going on holiday with a credit card offers
                                benefits to travelers, and making sure you have
                                the right travel credit card in your wallet
                                could save you some money.
                            </Text>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="why-should-you-get-a-travel-credit-card"
                                >
                                    Why should you get a travel credit card?
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    Using travel credit cards can limit the
                                    amount of cash you need to carry around with
                                    you while on holiday and provide other
                                    rewards and perks as well.
                                </Text>
                                <Text variant="80">
                                    Specialist travel cards allow you to spend
                                    money abroad without incurring the fee your
                                    regular credit card provider might charge
                                    for overseas usage. Most card providers
                                    charge a 3% fee on whatever you spend on
                                    your card while abroad. If you are away for
                                    more than a few days these fees will rack
                                    up. Let’s say you spend &pound;1,000 on your
                                    card while you are away &ndash; using a
                                    non-specialist credit card with a 3% foreign
                                    usage fee will cost you an extra &pound;30.
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="pre-paid-currency-cards"
                                >
                                    Pre-paid currency cards
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    While these are not credit cards, pre-paid
                                    currency cards can spare you a lot of hassle
                                    while travelling. These allow you to load up
                                    a card with foreign currency &ndash; like a
                                    pay-as-you-go phone &ndash; which you can
                                    then spend on your holiday. The obvious
                                    advantage of these cards is that they save
                                    you from having to carry around large
                                    quantities of cash, plus if one gets lost or
                                    stolen you can easily cancel it.
                                </Text>
                                <Text variant="80">
                                    Most banks will use the Visa exchange rate
                                    of the day you use your pre-paid card, so
                                    the exchange rate can fluctuate. Also, make
                                    sure you check the reviews on these cards
                                    before you commit to taking one out, as many
                                    will have unexpected charges on them. These
                                    can include charges for replacing a lost or
                                    stolen card (usually around
                                    &pound;5-&pound;10), monthly charges if the
                                    card is not used for a certain amount of
                                    time (usually around &pound;1 per month
                                    after 12 months of inactivity) and
                                    percentage fees on the money you load onto
                                    the card &ndash; so if the card provider
                                    charges a 2.9% fee and you load &pound;500
                                    onto it, you will be charged &pound;14.50.
                                </Text>
                                <Text variant="80">
                                    To find the best credit card for travelling
                                    simply check your eligibility for free.
                                    Using our comparison tool won’t impact your
                                    credit rating and you can browse and compare
                                    the cards available to you.
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading
                                    as="h3"
                                    variant="40"
                                    id="things-to-consider"
                                >
                                    Things to consider
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    You should think about where you are going
                                    on holiday before you decide on a travel
                                    card. While credit cards will be accepted on
                                    most European holidays, your plastic might
                                    not be so helpful if you’re trekking through
                                    the Himalayas.
                                </Text>
                                <Text variant="80">
                                    Generally speaking, Visa and Mastercard are
                                    more widely accepted around the world than
                                    American Express. Of course, if you are
                                    travelling to the USA then your Amex will be
                                    accepted almost everywhere. Check with your
                                    card provider to get details on the
                                    likelihood of your card being accepted in
                                    the country you are travelling to.
                                </Text>
                                <Text variant="80">
                                    Most credit cards will charge you a fee for
                                    withdrawing money from a cashpoint, and
                                    travel credit cards are no different. In
                                    fact, the majority of card providers will
                                    charge interest on the money you withdraw
                                    from a cashpoint from the day of the
                                    transaction, meaning you will have to pay
                                    interest on the money you take out, even if
                                    you pay off the card balance in full that
                                    month. You can check with your provider to
                                    see what this rate of interest will be, but
                                    we suggest you avoid using your travel
                                    credit card at ATMs unless absolutely
                                    necessary.
                                </Text>
                                <Text variant="80">
                                    We always advise that you pay your credit
                                    card balance off in full each month to avoid
                                    paying any interest. The same applies with
                                    travel cards. Make sure you pay off as much
                                    as possible &ndash; at least the minimum
                                    repayment and preferably the full balance
                                    &ndash; each month on your travel card.
                                </Text>
                                <Text variant="80">
                                    Lastly, does your current credit card offer
                                    Avios points? Double check with your lender
                                    &ndash; you might have earned enough to pay
                                    for your holiday flights outright.
                                </Text>
                            </Stack>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading as="h3" variant="40" id="safety-first">
                                    Safety first
                                </Heading>
                            </Box>
                            <Text variant="80">
                                Losing your credit card or having it stolen is
                                never fun, but it’s even worse when it happens
                                on holiday. Be sure to make a note of the number
                                to call should your card go missing so you can
                                cancel it immediately. This can usually be found
                                on the card itself, and there will also be an
                                international number to call should you lose
                                your card while traveling. Tourists are always
                                obvious targets for pickpockets and scammers, so
                                remember to be extra vigilant when using credit
                                cards abroad.
                            </Text>
                        </Box>

                        <Box mb="space60">
                            <Box mb="space15">
                                <Heading as="h3" variant="40" id="section-75">
                                    Section 75
                                </Heading>
                            </Box>
                            <Stack space="space05">
                                <Text variant="80">
                                    Using a credit card is a good idea even
                                    before you’ve left on your annual holiday
                                    thanks to Section 75. Section 75 is a
                                    consumer-friendly piece of legislation that
                                    offers free purchase protection on all
                                    credit card purchases between &pound;100 and
                                    &pound;35,000. If you have paid for your
                                    holiday on a credit card and something goes
                                    wrong, such as your travel company going
                                    bust, you can reclaim the money you spent
                                    under Section 75. Of course, you can only
                                    claim if the purchase was made on a credit
                                    card, so protect yourself by making sure you
                                    use one to pay for your trip. You don’t even
                                    have to make the entire payment on a credit
                                    card &ndash; as long as you pay for part of
                                    it with your card you will be covered.
                                </Text>
                                <Text variant="80">
                                    Section 75 also applies to purchases made
                                    overseas, so if you are paying for something
                                    expensive on your holiday, such as a tour or
                                    a piece of jewellery you want shipped home,
                                    make sure to use a credit card so you are
                                    protected should something go wrong.
                                </Text>
                                <Text variant="80">
                                    Check your eligibility for free to find the
                                    best credit card to use abroad.
                                </Text>
                            </Stack>
                        </Box>
                    </GuidesBox>
                </GuidesContainer>
            )
        default:
            return <></>
    }
}

export default FrequentlyAskedQuestions
