import { CreditCardProductCategory } from '@appTypes/EligibilityApi.interface'

import { CategoryComparatorKey } from './categorySortKeyComparatorKeys'

interface GetIsBestMatchCardOptions {
    currentCategory?: CreditCardProductCategory
    currentSortKey?: CategoryComparatorKey
    hasHighlightCard?: boolean
    index: number
}

export const getIsBestMatchCard = ({
    currentCategory,
    currentSortKey,
    hasHighlightCard,
    index,
}: GetIsBestMatchCardOptions): boolean => {
    if (
        currentCategory === CreditCardProductCategory.BestCards &&
        index === 0 &&
        currentSortKey === 'matchFactor' &&
        !hasHighlightCard
    ) {
        return true
    }
    return false
}
