import { captureException } from '@sentry/nextjs'
import Box from '@totallymoney/ui/components/Box'
import Grid from '@totallymoney/ui/components/Grid'
import Heading from '@totallymoney/ui/components/Heading'
import LinkButton from '@totallymoney/ui/components/LinkButton'
import LinkWithChevron from '@totallymoney/ui/components/LinkWithChevron'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import theme from '@totallymoney/ui/theme'
import Cookies from 'js-cookie'
import { useEffect, useMemo } from 'react'

import { getAppConfig } from '@utils/getAppConfig'
import getCategoryByCategoryId from '@utils/getCategoryByCategoryId'
import { getCategoryPathForId } from '@utils/getCategoryPathForId'
import getGuaranteedOfferType from '@utils/getGuaranteedOfferType'
import { getSanitisedCardName } from '@utils/getSanitisedCardName'
import { getShowOfferDurationContent } from '@utils/getShowOfferDurationContent'
import { sortCardsBySortKey } from '@utils/sortCardsBySortKey'

import {
    ApplicationURLTriggerLocation,
    CreditCardProductCategory,
    GetMinimumGuaranteedCreditLimitResponse,
    ScoredCreditCardProduct,
    ScoredCreditCardProductsCategory,
    ScoredExperiment,
} from '@appTypes/EligibilityApi.interface'

import useAppQueryState from '@hooks/useAppQueryState'
import useLogEvents from '@hooks/useLogEvents'
import usePostCreditCardsEmailTrigger from '@hooks/usePostCreditCardsEmailTrigger'
import useSetState from '@hooks/useSetState'

import useGetCreditCardApplicationUrl from '@routes/ScoredTableRoute/hooks/useGetCardApplicationUrl'
import useGetDifferenceInNewCreditCardsOffers from '@routes/ScoredTableRoute/hooks/useGetDifferenceInNewCreditCardsOffers'
import {
    getIsSponsoredCreditCard,
    getSponsoredProducts,
} from '@routes/ScoredTableRoute/utils/getSponsoredProducts'

import AppAdoptionHeader from '@components/AppAdoptionHeader'
import AppAdoptionModal from '@components/AppAdoptionModal'
import BestMatchModal from '@components/BestMatchModal'
import CardTablePagination from '@components/CardTablePagination'
import CreditLimitModal from '@components/CreditLimitModal'
import ErrorBoundary from '@components/ErrorBoundary'
import GuaranteedOfferModal from '@components/GuaranteedOfferModal'
import MinimumCreditLimitModal from '@components/MinimumCreditLimitModal/MinimumCreditLimitModal'
import NextLinkButton from '@components/NextLinkButton'
import PreApprovedModal from '@components/PreApprovedModal'
import RepresentativeExampleModal from '@components/RepresentativeExampleModal'

import AdvertisementBanner from './components/AdvertisementBanner'
import DownsellBanner from './components/DownsellBanner'
import HighlightHero from './components/HighlightHero'
import ScoredTableHeader from './components/ScoredTableHeader'
import ScoredTableItem from './components/ScoredTableItem'
import ScoredTableNewOfferCard from './components/ScoredTableNewOfferCard'
import useLogCheckMatchFactorRankingError from './hooks/useLogCheckMatchFactorRankingError'
import {
    CategoryComparatorKey,
    CategorySortKeyComparatorKeys,
    categorySortKeyComparatorKeys,
} from './utils/categorySortKeyComparatorKeys'
import { getCategorySortKeysToShow } from './utils/getCategorySortKeysToShow'
import {
    getDownsellCreditCards,
    getIsDownsellCreditCard,
} from './utils/getDownsellCreditCards'
import { HighlightCard, HighlightCardStatus } from './utils/getHighlightCard'
import { getIsBestMatchCard } from './utils/getIsBestMatchCard'
import { getValidCategorySortKey } from './utils/getValidCategorySortKey'

interface ScoredTableProps {
    activeCategoryId: CreditCardProductCategory
    hasEverUsedNativeApp?: boolean
    highlightCard: HighlightCard
    minCreditLimitData?: GetMinimumGuaranteedCreditLimitResponse
    scoredCategories: ScoredCreditCardProductsCategory[]
    scoredExperiments?: ScoredExperiment[]
    thirdPartyReference?: string
}

interface ScoredTableState {
    selectedCategory: CreditCardProductCategory
    selectedSortKey: keyof CategorySortKeyComparatorKeys[CreditCardProductCategory]
    numberOfCardsToShow: number
}

const NUMBER_OF_CARDS_TO_SHOW = 15

enum ModalType {
    AppAdoption = 'AppAdoption',
    BestMatch = 'BestMatch',
    RepresentativeExample = 'RepresentativeExample',
    PreApproved = 'PreApproved',
    MinimumCreditLimit = 'MinimumCreditLimit',
    GuaranteedOfferModal = 'GuaranteedOfferModal',
    CreditLimitModal = 'CreditLimitModal',
}

interface ModalState {
    activeCard: ScoredCreditCardProduct | null
    activeModal: ModalType | null
    creditCardApplicationUrl: string | null
}

export const defaultModalState: ModalState = {
    activeCard: null,
    activeModal: null,
    creditCardApplicationUrl: null,
}

const ScoredTable = ({
    activeCategoryId,
    hasEverUsedNativeApp,
    highlightCard,
    minCreditLimitData,
    scoredCategories,
    scoredExperiments,
    thirdPartyReference,
}: ScoredTableProps) => {
    const appConfig = getAppConfig()
    const logEvents = useLogEvents()
    const { appQueryState, getRouteWithAppState, updateAppQueryState } =
        useAppQueryState()
    const getCardApplicationUrl = useGetCreditCardApplicationUrl()
    const postCreditCardsEmailTrigger = usePostCreditCardsEmailTrigger()
    const logCheckMatchFactorRankingError = useLogCheckMatchFactorRankingError()

    const numberOfNewOffers =
        useGetDifferenceInNewCreditCardsOffers(scoredCategories)

    const [modalState, updateModalState] =
        useSetState<ModalState>(defaultModalState)

    const [creditCardsState, setCreditCardsState] =
        useSetState<ScoredTableState>({
            selectedCategory: activeCategoryId,
            selectedSortKey: getValidCategorySortKey({
                categoryId: appQueryState.categoryId!,
                queryOption: appQueryState.sortBy as CategoryComparatorKey,
            }),
            numberOfCardsToShow:
                appQueryState.numberOfCardsToShow ?? NUMBER_OF_CARDS_TO_SHOW,
        })

    const activeCategoryPath = getCategoryPathForId(
        creditCardsState.selectedCategory
    )

    const sponsoredCreditCards = useMemo(() => {
        return getSponsoredProducts(
            scoredCategories,
            creditCardsState.selectedCategory
        )
    }, [scoredCategories, creditCardsState.selectedCategory])

    const activeCategory = getCategoryByCategoryId(
        scoredCategories,
        creditCardsState.selectedCategory
    )

    const downsellCreditCards = useMemo(() => {
        return getDownsellCreditCards(
            scoredCategories,
            creditCardsState.selectedCategory
        )
    }, [scoredCategories, creditCardsState.selectedCategory])

    const activeCategoryCreditCards = useMemo(() => {
        if (activeCategory == null) {
            return null
        }
        const sortedCards = sortCardsBySortKey({
            cards: activeCategory.creditCards,
            category: creditCardsState.selectedCategory,
            sortKey: getValidCategorySortKey({
                categoryId: creditCardsState.selectedCategory,
                queryOption: creditCardsState.selectedSortKey,
            }),
            categorySortKeyComparatorKeys,
        })
        return [
            ...(sponsoredCreditCards ?? []),
            ...sortedCards,
            ...(downsellCreditCards ?? []),
        ]
    }, [
        creditCardsState.selectedSortKey,
        creditCardsState.selectedCategory,
        activeCategory,
        downsellCreditCards,
        sponsoredCreditCards,
    ])

    useEffect(() => {
        logEvents.logScoredTableUpdateEvent({
            cards: activeCategoryCreditCards,
            minCreditLimit: minCreditLimitData?.minimumGuaranteedCreditLimit,
            category: creditCardsState.selectedCategory,
            sortedBy: creditCardsState.selectedSortKey,
            seenNewOffers: numberOfNewOffers > 0,
        })

        if (sponsoredCreditCards?.length) {
            logEvents.logFeatureCardsViewEvent({
                category: creditCardsState.selectedCategory,
                numberOfFeaturedCards: sponsoredCreditCards.length,
            })
        }
    }, [activeCategoryCreditCards])

    const activeSortOptions = useMemo(() => {
        return getCategorySortKeysToShow(creditCardsState.selectedCategory)
    }, [creditCardsState.selectedCategory])

    const onCategoryChange = (category: CreditCardProductCategory) => {
        logEvents.logCategoryChangeEvent({
            category,
        })

        setCreditCardsState({
            selectedCategory: category,
            selectedSortKey: getValidCategorySortKey({
                categoryId: category,
                queryOption: creditCardsState.selectedSortKey,
            }),
            numberOfCardsToShow: NUMBER_OF_CARDS_TO_SHOW,
        })
    }

    const onSortChange = (
        sortKey: keyof CategorySortKeyComparatorKeys[CreditCardProductCategory]
    ) => {
        logEvents.logSortChangeEvent({
            sortBy: sortKey,
            category: creditCardsState.selectedCategory,
        })

        setCreditCardsState({ selectedSortKey: sortKey })

        updateAppQueryState({
            sortby: sortKey,
        })
    }

    const resetModalState = () => {
        updateModalState(defaultModalState)
    }

    const onShowMoreResults = () => {
        const numberOfCardsToShow =
            creditCardsState.numberOfCardsToShow + NUMBER_OF_CARDS_TO_SHOW

        setCreditCardsState({
            numberOfCardsToShow: numberOfCardsToShow,
        })

        updateAppQueryState({
            show: `${numberOfCardsToShow}`,
        })
    }

    const onSeeMoreDetailsPress = ({
        card,
        cardPosition,
        trigger = ApplicationURLTriggerLocation.Row,
    }: {
        card: ScoredCreditCardProduct
        cardPosition: number
        trigger?: ApplicationURLTriggerLocation
    }) => {
        logEvents.logNavigateToCardPageEvent({
            productCode: card.productCode,
            rowNumber: cardPosition,
            trigger,
        })
    }

    const onApplyPress = ({
        card,
        isBestMatchCard,
        cardPosition,
        applicationUrl,
        trigger = ApplicationURLTriggerLocation.Row,
    }: {
        card: ScoredCreditCardProduct
        isBestMatchCard: boolean
        cardPosition: number
        applicationUrl: string
        trigger?: ApplicationURLTriggerLocation
    }) => {
        logEvents.logCardApplyEvent({
            category: creditCardsState.selectedCategory,
            clickSource: appConfig.CLICK_SOURCE_WHITELABEL,
            isBestMatchCard: isBestMatchCard,
            isCounterOffer: card.guaranteedRate?.isCounterOffer ?? false,
            productCode: card.productCode,
            rowNumber: cardPosition,
            score: card.score,
            status: card.status,
            trigger,
        })

        window.open(applicationUrl, '_blank')
    }

    const renderItem = (card: ScoredCreditCardProduct, index: number) => {
        const isDownsellCard = getIsDownsellCreditCard(card)
        const isFirstDownsellCard = isDownsellCard && card.__isFirstDownsellCard
        const isSponsoredCard = getIsSponsoredCreditCard(card)
        const cardPosition = index + 1

        const isBestMatch = getIsBestMatchCard({
            currentCategory: creditCardsState.selectedCategory,
            currentSortKey: creditCardsState.selectedSortKey,
            hasHighlightCard:
                highlightCard?.status === HighlightCardStatus.CardEligible,
            index,
        })

        const applicationUrl = getCardApplicationUrl({
            category: isDownsellCard
                ? CreditCardProductCategory.PoorCredit
                : creditCardsState.selectedCategory,
            creditCard: card,
            creditCardIndex: cardPosition,
            isBestMatchCard: isBestMatch,
            thirdPartyReference: thirdPartyReference!,
            trigger: ApplicationURLTriggerLocation.Row,
        })

        const sanitisedCardName = getSanitisedCardName(card.name)

        return (
            <ErrorBoundary
                fallback={null}
                onError={({ error }) => captureException(error)}
            >
                {isFirstDownsellCard ? (
                    <Box mb="space30">
                        <DownsellBanner />
                    </Box>
                ) : null}
                <Box
                    bg={isSponsoredCard ? theme.backgroundLightBlue : 'none'}
                    pb={isSponsoredCard ? 'space30' : 0}
                >
                    {isSponsoredCard ? <AdvertisementBanner /> : null}
                    <Grid>
                        <Box gridColumn={'1/13'}>
                            <ScoredTableItem
                                item={card}
                                category={creditCardsState.selectedCategory}
                                isBestMatch={isBestMatch}
                                minCreditLimitData={minCreditLimitData}
                                onShowBestMatchModal={() =>
                                    updateModalState({
                                        activeModal: ModalType.BestMatch,
                                    })
                                }
                                onShowPreApprovedModal={() =>
                                    updateModalState({
                                        activeModal: ModalType.PreApproved,
                                    })
                                }
                                onShowRepresentativeExampleModal={() =>
                                    updateModalState({
                                        activeModal:
                                            ModalType.RepresentativeExample,
                                    })
                                }
                                onShowGuaranteedOfferModal={() =>
                                    updateModalState({
                                        activeModal:
                                            ModalType.GuaranteedOfferModal,
                                        activeCard: card,
                                    })
                                }
                                onShowCreditLimitModal={() =>
                                    updateModalState({
                                        activeModal: ModalType.CreditLimitModal,
                                    })
                                }
                                onShowMinimumCreditLimitModal={() =>
                                    updateModalState({
                                        activeModal:
                                            ModalType.MinimumCreditLimit,
                                    })
                                }
                                renderButtons={() => {
                                    return (
                                        <Stack
                                            space="space10"
                                            display="flex"
                                            alignItems={[
                                                'center',
                                                'unset',
                                                'unset',
                                            ]}
                                        >
                                            <LinkButton
                                                text="Go to site"
                                                href={applicationUrl}
                                                variant="primary"
                                                rel="nofollow noopener"
                                                target="_blank"
                                                data-gtm-event={
                                                    card.sponsored
                                                        ? 'applyNow-sponsored-row-postClick'
                                                        : 'applyNow-table-postClick'
                                                }
                                                onClick={event => {
                                                    event.preventDefault()
                                                    onApplyPress({
                                                        card,
                                                        cardPosition,
                                                        applicationUrl,
                                                        isBestMatchCard:
                                                            isBestMatch,
                                                    })
                                                }}
                                            />
                                            <NextLinkButton
                                                to={getRouteWithAppState([
                                                    isDownsellCard
                                                        ? 'credit-cards-bad-credit'
                                                        : activeCategoryPath,
                                                    'card',
                                                    sanitisedCardName,
                                                ])}
                                                shallow={true}
                                                text="See more details"
                                                variant="tertiaryTransparent"
                                                onClick={() =>
                                                    onSeeMoreDetailsPress({
                                                        card,
                                                        cardPosition,
                                                    })
                                                }
                                            />
                                        </Stack>
                                    )
                                }}
                            />
                        </Box>
                    </Grid>
                </Box>
            </ErrorBoundary>
        )
    }

    const shouldShowAppPromotionHeader =
        typeof window !== 'undefined' &&
        window.innerWidth <= 425 &&
        appQueryState.showAppPromotion === 'true' &&
        hasEverUsedNativeApp

    const shouldShowAppPromotionModal =
        shouldShowAppPromotionHeader &&
        Cookies.get('appPromotionPreferWebToAppJourney') !== 'true'

    useEffect(() => {
        if (shouldShowAppPromotionModal) {
            updateModalState({
                activeModal: ModalType.AppAdoption,
            })
            logEvents.logAppPromotionView({ promotion: 'creditCardsTable' })
        }
    }, [shouldShowAppPromotionModal])

    useEffect(() => {
        if (activeCategoryCreditCards == null) {
            return
        }
        postCreditCardsEmailTrigger(
            activeCategoryCreditCards.filter(
                card => !getIsSponsoredCreditCard(card)
            ),
            creditCardsState.selectedCategory
        )
    }, [
        activeCategoryCreditCards,
        creditCardsState.selectedCategory,
        postCreditCardsEmailTrigger,
    ])

    useEffect(() => {
        if (activeCategoryCreditCards == null) {
            return
        }

        if (creditCardsState.selectedSortKey === 'matchFactor') {
            logCheckMatchFactorRankingError(activeCategoryCreditCards)
        }
    }, [
        logCheckMatchFactorRankingError,
        activeCategoryCreditCards,
        creditCardsState.selectedCategory,
        creditCardsState.selectedSortKey,
    ])

    useEffect(() => {
        setCreditCardsState({ selectedCategory: activeCategoryId })
    }, [activeCategoryId])

    const totalCreditCards = scoredCategories[0].totalCreditCards
    const isHighlightCardEligible =
        highlightCard.status === HighlightCardStatus.CardEligible

    const highlightCardApplicationUrl = isHighlightCardEligible
        ? getCardApplicationUrl({
              category: creditCardsState.selectedCategory,
              creditCard: highlightCard.card,
              creditCardIndex: 0,
              isBestMatchCard: false,
              thirdPartyReference: thirdPartyReference!,
              trigger: ApplicationURLTriggerLocation.Header,
          })
        : null

    const modalGuaranteedOfferType = getGuaranteedOfferType({
        creditCard: modalState.activeCard,
    })

    return (
        <>
            {shouldShowAppPromotionHeader ? (
                <AppAdoptionHeader customerId={appQueryState.customerId} />
            ) : null}

            <Box bg={theme.productNeutral60}>
                <Grid>
                    <Box mt="space30" mb="space10" gridColumn={'1/13'}>
                        <LinkWithChevron
                            href={`${appConfig.ACCOUNT_URL}offers?customerId=${appQueryState.customerId}`}
                            variant="100"
                            direction="left"
                        >
                            Back to offers
                        </LinkWithChevron>
                    </Box>
                </Grid>
                <Grid>
                    <Box gridColumn={'1/13'} pb="space30">
                        <Box mb="space10">
                            <Heading variant="100">Your card results</Heading>
                        </Box>
                        <Box>
                            <Text variant="120">
                                {totalCreditCards}{' '}
                                {totalCreditCards === 1 ? 'offer' : 'offers'}{' '}
                                across all categories
                            </Text>
                        </Box>
                    </Box>
                    {numberOfNewOffers > 0 ? (
                        <Box gridColumn={['1/13', '1/13', '1/9']} mb="space30">
                            <ScoredTableNewOfferCard
                                numberOfNewOffers={numberOfNewOffers}
                            />
                        </Box>
                    ) : null}
                </Grid>

                {highlightCard.status !== HighlightCardStatus.NoData ? (
                    <Grid>
                        <Box
                            gridColumn={'1/13'}
                            mb="space30"
                            data-testid="highlight-hero"
                        >
                            <HighlightHero
                                applicationUrl={highlightCardApplicationUrl}
                                scoredCategories={scoredCategories}
                                highlightedCreditCard={highlightCard}
                                onApplyButtonPress={() => {
                                    if (isHighlightCardEligible) {
                                        onApplyPress({
                                            applicationUrl:
                                                highlightCardApplicationUrl!,
                                            card: highlightCard.card,
                                            isBestMatchCard: false,
                                            cardPosition: 0,
                                            trigger:
                                                ApplicationURLTriggerLocation.Header,
                                        })
                                    }
                                }}
                                onSeeMoreDetailsPress={() => {
                                    if (isHighlightCardEligible) {
                                        onSeeMoreDetailsPress({
                                            card: highlightCard.card,
                                            cardPosition: 0,
                                            trigger:
                                                ApplicationURLTriggerLocation.Header,
                                        })
                                    }
                                }}
                                onTooltipPress={() => {
                                    updateModalState({
                                        activeModal: ModalType.PreApproved,
                                    })
                                }}
                                onShowGuaranteedOfferModal={() =>
                                    updateModalState({
                                        activeModal:
                                            ModalType.GuaranteedOfferModal,
                                        activeCard:
                                            highlightCard.card as ScoredCreditCardProduct,
                                    })
                                }
                            />
                        </Box>
                    </Grid>
                ) : null}

                <Box mb="space50">
                    <ScoredTableHeader
                        categories={scoredCategories}
                        activeCategory={activeCategoryId}
                        onCategoryChange={onCategoryChange}
                        sortValue={creditCardsState.selectedSortKey}
                        onSortChange={sortKey =>
                            onSortChange(
                                sortKey as keyof CategorySortKeyComparatorKeys[CreditCardProductCategory]
                            )
                        }
                        sortOptions={activeSortOptions}
                        onShowMinimumCreditLimitModal={() =>
                            updateModalState({
                                activeModal: ModalType.MinimumCreditLimit,
                            })
                        }
                    />
                </Box>
                <Box as="ul" display="grid" gridGap="space30" mb="space30">
                    {activeCategoryCreditCards
                        ?.slice(0, creditCardsState.numberOfCardsToShow)
                        .map((card, index) => (
                            <Box
                                as="li"
                                key={`${card.productCode}_${
                                    getIsSponsoredCreditCard(card)
                                        ? 'sponsored'
                                        : ''
                                }`}
                            >
                                {renderItem(card, index)}
                            </Box>
                        ))}
                </Box>
                <Grid>
                    {activeCategory != null ? (
                        <Box gridColumn={'1/13'} mb="space30">
                            <CardTablePagination
                                totalCreditCards={
                                    activeCategory.totalCreditCards
                                }
                                numberOfCardsToShow={
                                    creditCardsState.numberOfCardsToShow!
                                }
                                showMoreResultsButton={
                                    creditCardsState.numberOfCardsToShow <
                                    activeCategory.totalCreditCards
                                }
                                onShowMoreResults={onShowMoreResults}
                            />
                        </Box>
                    ) : null}
                </Grid>
            </Box>
            <PreApprovedModal
                showModal={modalState.activeModal == ModalType.PreApproved}
                onModalClose={resetModalState}
            />
            <RepresentativeExampleModal
                showModal={
                    modalState.activeModal == ModalType.RepresentativeExample
                }
                onModalClose={resetModalState}
            />
            <BestMatchModal
                showModal={modalState.activeModal == ModalType.BestMatch}
                onModalClose={resetModalState}
            />
            <AppAdoptionModal
                showModal={modalState.activeModal == ModalType.AppAdoption}
                onModalClose={resetModalState}
                customerId={appQueryState.customerId}
            />
            <MinimumCreditLimitModal
                showModal={
                    modalState.activeModal == ModalType.MinimumCreditLimit
                }
                onModalClose={resetModalState}
            />
            <CreditLimitModal
                showModal={
                    modalState.activeModal === ModalType.CreditLimitModal
                }
                onModalClose={resetModalState}
            />
            <GuaranteedOfferModal
                showModal={
                    modalState.activeModal === ModalType.GuaranteedOfferModal
                }
                onModalClose={resetModalState}
                offerType={modalGuaranteedOfferType}
                showOfferDurationContent={getShowOfferDurationContent({
                    category: creditCardsState.selectedCategory,
                    card: modalState.activeCard,
                })}
            />
        </>
    )
}

export default ScoredTable
