import ErrorPage from '@components/ErrorPage/ErrorPage'

const UnscoredTableError = () => {
    return (
        <ErrorPage
            title="Oh! This doesn’t look right"
            subtitle="Sorry, the page you requested hit an error. "
        />
    )
}

export default UnscoredTableError
