export const KBA_SCENARIO_KEYS = {
    FOUND_BY_ALL_NEW_USER: 'foundByAllNewUser',
    KBA_OK_HD_NOT_FOUND: 'KbaOkHdNotFound',
    NO_VNU_HD_FOUND: 'NoVnuHdFound',
    NO_VNU_HD_NOT_FOUND: 'NoVnuHdNotFound',
    ONBOARDING_REQUIRED_HD_FOUND: 'onboardingRequiredHDFound',
    ONBOARDING_REQUIRED_HD_NOT_FOUND: 'onboardingRequiredHDNotFound',
    KBA_FAILED_HD_FOUND: 'KBAFailedHDFound',
    KBA_FAILED_HD_NOT_FOUND: 'KBAFailedHDNotFound',
    THIN_FILE_HD_FOUND: 'thinFileHDFound',
    THIN_FILE_HD_NOT_FOUND: 'thinFileNotFoundHD',
} as const

export type KbaScenario =
    (typeof KBA_SCENARIO_KEYS)[keyof typeof KBA_SCENARIO_KEYS]

export const hasKbaScenario = (
    kbaScenario?: unknown
): kbaScenario is KbaScenario => {
    if (kbaScenario == null) {
        return false
    }
    return Object.values(KBA_SCENARIO_KEYS).some(
        key => key === (kbaScenario as KbaScenario)
    )
}
