import Badge from '@totallymoney/ui/components/Badge'
import Heading from '@totallymoney/ui/components/Heading'
import Modal from '@totallymoney/ui/components/Modal'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import { useEffect, useState } from 'react'

import { GetMinimumGuaranteedCreditLimitResponse } from '@appTypes/EligibilityApi.interface'

import useAppQueryState from '@hooks/useAppQueryState'

import BackgroundBlob from '../BackgroundBlob'
import GuaranteedCreditLimitIntroFormWrapper from '../GuaranteedCreditLimitIntroFormWrapper/GuaranteedCreditLimitIntroFormWrapper'

interface GuaranteedCreditLimitIntroModalProps {
    minCreditLimitData: GetMinimumGuaranteedCreditLimitResponse | null
    onCloseModal: () => void
}

const GuaranteedCreditLimitIntroModal = ({
    minCreditLimitData,
    onCloseModal,
}: GuaranteedCreditLimitIntroModalProps) => {
    const { isReady } = useAppQueryState()
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (isReady) {
            setShowModal(true)
        }
    }, [isReady])

    function onClose() {
        onCloseModal()
    }

    const hasMinCreditLimitRequestExpired =
        minCreditLimitData?.isExpired === true

    return (
        <Modal isOpen={showModal} isFullScreen={true} onClose={onClose}>
            <>
                <BackgroundBlob />
                <Stack space="space20" mb="space30">
                    {hasMinCreditLimitRequestExpired ? (
                        <Heading variant="80">
                            Add your minimum credit limit
                        </Heading>
                    ) : (
                        <>
                            <Badge text="NEW" size="medium" />
                            <Heading variant="80">
                                Introducing Guaranteed Credit Limit
                            </Heading>
                        </>
                    )}

                    <Text color="light" variant="100">
                        Be confident you can get the amount you need with a
                        guaranteed credit limit
                    </Text>
                </Stack>
                <GuaranteedCreditLimitIntroFormWrapper onFormClose={onClose} />
            </>
        </Modal>
    )
}

export default GuaranteedCreditLimitIntroModal
