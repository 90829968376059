import {
    CreditCardProductCategory,
    ScoredCreditCardProduct,
    ScoredCreditCardProductsCategory,
} from '@appTypes/EligibilityApi.interface'

export type ScoredCreditCardProductSponsored = ScoredCreditCardProduct & {
    __sponsored: true
}

const SPONSORED_CARD_FLAG = '__sponsored'

export const getIsSponsoredCreditCard = (
    card: any
): card is ScoredCreditCardProductSponsored => {
    return card.hasOwnProperty(SPONSORED_CARD_FLAG)
}

export const getSponsoredProducts = (
    scoredCategories: ScoredCreditCardProductsCategory[],
    selectedCategory: CreditCardProductCategory
): ScoredCreditCardProductSponsored[] | null => {
    const activeCategory = scoredCategories.find(
        item => item.id === selectedCategory
    )
    const sponsoredCards: ScoredCreditCardProductSponsored[] | undefined =
        activeCategory?.creditCards
            .filter(
                item =>
                    item.sponsored === true &&
                    item.productCategories.some(
                        category => category.name === selectedCategory
                    )
            )
            .map(item => ({ ...item, [SPONSORED_CARD_FLAG]: true }))

    if (sponsoredCards && sponsoredCards.length > 0) {
        return sponsoredCards
    }

    return null
}
