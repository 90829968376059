import Box from '@totallymoney/ui/components/Box'
import Button from '@totallymoney/ui/components/Button'
import RadioGroup from '@totallymoney/ui/components/RadioGroup'
import Text from '@totallymoney/ui/components/Text'
import TextField from '@totallymoney/ui/components/TextField'
import theme from '@totallymoney/ui/theme'
import { useFormikContext } from 'formik'
import Image from 'next/legacy/image'
import React, { useRef } from 'react'

import insightsLightBulb from '../../../../../../public/images/insights-lightbulb.svg'
import { GuaranteedCreditLimitIntroFormValues } from './GuaranteedCreditLimitIntroForm.validation'

interface GuaranteedCreditLimitIntroFormProps {
    onFormClose: () => void
}

const CREDIT_LIMIT_OPTIONS = [
    { label: '£250', value: 250 },
    { label: '£500', value: 500 },
    { label: '£1000', value: 1000 },
    { label: '£1500', value: 1500 },
]

const GuaranteedCreditLimitIntroForm = ({
    onFormClose,
}: GuaranteedCreditLimitIntroFormProps) => {
    const customTextField = useRef<HTMLInputElement | null>(null)

    const {
        setFieldValue,
        setFieldTouched,
        submitForm,
        values,
        errors,
        touched,
        dirty,
        isSubmitting,
        status,
    } = useFormikContext<GuaranteedCreditLimitIntroFormValues>()

    React.useEffect(() => {
        if (values.minCreditLimitOption != null) {
            setFieldValue('showCustomField', false, false)
        }
    }, [values.minCreditLimitOption])

    React.useLayoutEffect(() => {
        if (values.showCustomField) {
            customTextField?.current?.focus()
        }
    }, [values.showCustomField])

    return (
        <>
            <RadioGroup
                key={values.showCustomField ? 'customOption' : 'manualOption'}
                name="minCreditLimitOption"
                legend="Your ideal credit limit"
                variant="pill"
                options={CREDIT_LIMIT_OPTIONS}
                value={values.minCreditLimitOption}
                fullWidth={true}
                onChange={evt =>
                    setFieldValue(
                        'minCreditLimitOption',
                        Number(evt.target.value)
                    )
                }
            />

            <Box>
                <Box mt="space20" mb="space30">
                    {!values.showCustomField ? (
                        <Box ml="space10">
                            <Text variant="100">
                                <Button
                                    variant="tertiaryTransparent"
                                    onClick={() => {
                                        setFieldValue(
                                            'showCustomField',
                                            true,
                                            false
                                        )
                                        setFieldValue(
                                            'minCreditLimitOption',
                                            null,
                                            false
                                        )
                                    }}
                                    style={{ padding: 0 }}
                                >
                                    Enter a different amount
                                </Button>
                            </Text>
                        </Box>
                    ) : (
                        <TextField
                            ref={customTextField}
                            name="minCreditLimitCustom"
                            prefix="£"
                            autoComplete="off"
                            autoCapitalize="none"
                            value={values.minCreditLimitCustom ?? ''}
                            error={!!errors.minCreditLimitCustom}
                            data-testid="minCreditLimitCustom"
                            touched={!!touched.minCreditLimitCustom}
                            validationMessage={errors.minCreditLimitCustom}
                            pattern="\d*"
                            onChange={e =>
                                setFieldValue(
                                    'minCreditLimitCustom',
                                    e.target.value
                                )
                            }
                            onBlur={() => {
                                setFieldTouched('minCreditLimitCustom', true)
                            }}
                        />
                    )}
                </Box>

                <Box display="flex">
                    <Box mr="space10">
                        <Image
                            src={insightsLightBulb}
                            alt="Light bulb with a light indigo background"
                        />
                    </Box>
                    <Box flex={1}>
                        <Text variant="100" color="dark">
                            <span style={{ fontWeight: 'bold' }}>Tip:</span>{' '}
                            Enter a credit limit based on existing balance or
                            spending plans, and we’ll show you the lenders who
                            can offer what you’ve requested
                        </Text>
                    </Box>
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                flex={1}
                mt={['space60', 'space80']}
            >
                {status ? (
                    <Box mb="space20">
                        <Text
                            style={{ color: theme.alertError120 }}
                            textAlign="center"
                        >
                            {status}
                        </Text>
                    </Box>
                ) : null}

                <Box
                    display="grid"
                    flexDirection="column"
                    gridGap="22px"
                    alignSelf="center"
                    width={['100%', '384px']}
                >
                    <Button
                        variant="primary"
                        onClick={submitForm}
                        disabled={!dirty}
                        loading={isSubmitting}
                    >
                        Find Cards
                    </Button>
                    <Button
                        variant="secondaryOutline"
                        onClick={onFormClose}
                        disabled={isSubmitting}
                    >
                        Enter a limit later
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default GuaranteedCreditLimitIntroForm
